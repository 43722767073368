@import '~angular/angular-csp.css';
@import 'thirdparty/thirdparty.less';
@import 'thirdparty/bs3-custom-vars.less';
@import 'thirdparty/bs3-custom-buttons.less';
@import 'animations.less';

/* Firefox is weird with fieldsets.
 * See: http://stackoverflow.com/questions/17408815/fieldset-resizes-wrong-appears-to-have-unremovable-min-width-min-content/17863685#17863685
 */
@-moz-document url-prefix() {
  fieldset {
    display: table-cell;
  }
}

html {
  font-size: 16px;
}

body {
  // Make sure the body never has padding on the right side.
  // When modals open, they add padding to the body.
  padding-right: 0 !important;
  background-color: @fps-background;
}

.no-break {
  display: inline-block;
}

.break-text {
  word-break: break-word;
  word-wrap: break-word;
}

.sr-only {
  display: none !important;
}

.subtitle-stats {
  padding-top: 5px;
  padding-bottom: 5px;
}

[ng-cloak],
[data-ng-cloak],
.ng-cloak {
  display: none !important;
}

.text-no-transform {
  text-transform: none;
}

.close:disabled {
  // Close buttons that are disabled.
  cursor: default;
  opacity: 0.75;
}

#wi_id {
  display: none !important;
}

.grayscale {
  -webkit-filter: grayscale(1) contrast(1.2) brightness(1.2);
  -webkit-filter: grayscale(100%) contrast(1.2) brightness(1.2);
  -moz-filter: grayscale(100%) contrast(1.2) brightness(1.2);
  filter: gray contrast(1.2) brightness(1.2);
  filter: grayscale(100%) contrast(1.2) brightness(1.2);
}

.center {
  text-align: center;
}

.left-text {
  text-align: left;
}

.right-text {
  text-align: right;
}

/*--------- Not Found Page ------------------------*/
.notfound {
  .fourohfour {
    font-size: 800%;
    color: @fps-text-secondary;
    font-weight: 100;
    font-stretch: wider;
  }
  .header {
    font-size: 200%;
    color: @sb-green-type;
    padding: 15px 0px;
    font-weight: 300;
    line-height: 1.16;
  }
  li {
    padding: 5px 0px;
  }
  margin: 15px 30px;
}

.sidebar-desc-box {
  &.vcenter {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  background: @fps-box-background;
  padding: 15px;
  h4 {
    margin-top: 0;
    text-transform: none;
  }
  p:last-child {
    margin-bottom: 0;
  }
}

/*----- Move Indicator (drag handle) ---------------*/
.move-indicator {
  background: url('./../img/moveHandleBg.png') top left repeat;
  width: 6px;
}

/*------ Placeholder Text ------*/
::-webkit-input-placeholder {
  font-style: italic;
  color: @sb-gray !important;
}
::-moz-placeholder {
  font-style: italic;
  color: @sb-gray !important;
}
:-ms-input-placeholder {
  font-style: italic;
  color: @sb-gray !important;
}
input:-moz-placeholder {
  font-style: italic;
  color: @sb-gray !important;
}

/*---- Drag and Drop Angular Styles ------*/
ul[data-dnd-list] * {
  pointer-events: none;
}
ul[data-dnd-list],
ul[data-dnd-list] > li {
  pointer-events: auto;
  position: relative;
}
li.dndDraggingSource {
  display: none;
}

.model-changes {
  ul.extra-list {
    display: block;
    background: @fps-box-background;
    padding: 10px;
    li {
      font-size: 14px;
      line-height: 15px;
      padding-bottom: 10px;
    }
    li:last-child {
      padding-bottom: 0;
    }
  }
}

/* ul with dl style */
ul.dl-list {
  display: block;
  width: 192px;
  background: @fps-box-background;
  border-left: 3px solid @fps-text;
  padding: 10px;
  li {
    font-size: 14px;
    font-weight: 400;
    padding-bottom: 10px;
  }
  li:last-child {
    padding-bottom: 0;
  }
  li span:first-child {
    display: block;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: normal;
    color: @fps-text-secondary;
    line-height: 0.9em;
  }
}
.disabled-drag-list {
  .move-indicator {
    cursor: auto;
    border-color: @sb-gray-light !important;
  }
}

/* Figures/images */
figure {
  max-width: 70%;
  margin: 0 auto 15px;
  margin-left: auto;

  img {
    margin: 0 auto;
  }
}

figcaption {
  font-style: italic;
  text-align: center;
}

.process-start-link-help {
  font-weight: bold;
  .fa,
  .fas,
  .far,
  .fab {
    margin-right: 3px;
  }
}
.rightside-image {
  padding-left: 15px;
  padding-bottom: 10px;
  float: right;
}
.floated-padded-top-image {
  padding-top: 10px;
}
.leftside-image {
  padding-right: 15px;
  padding-bottom: 10px;
  float: left;
}
.side-image-text {
  padding-bottom: 10px;
}
.fluid-image {
  max-width: 100%;
}

.indented-radio-option {
  margin-left: 32px;
}

.property-title {
  color: @fps-text-secondary;
  text-transform: uppercase;
  font-weight: normal;
  display: inline;
  font-size: 0.9em;
}

.inline-loader {
  display: flex;
  justify-content: center;
  gap: 3px;
}

dl.properties {
  dt {
    .property-title;
  }
  dt:after {
    content: ': ';
  }
  dd {
    display: inline;
  }
  dd:after {
    content: ' ';
    margin-bottom: 3px;
    display: block;
  }
}

.red {
  color: @sb-red-type;
}

.green {
  color: @sb-green-type;
}

.blue {
  color: @sb-blue;
}

.yellow {
  color: @sb-yellow;
}

.orange {
  color: @sb-orange;
}

sb-collapsed-actions {
  button-content,
  button-content {
    .fa,
    .fas,
    .far,
    .fab {
      &.fa-caret-right,
      &.fa-caret-down {
        padding-left: 10px;
      }
    }
  }

  custom-trigger .btn-link {
    padding: 5px;
  }
  .btn-int .caret,
  .btn-link {
    margin: -2px 0 0 2px;
    color: @sb-green-type;
  }
  .btn-int:hover .caret,
  .btn-int:focus .caret,
  .btn-int:active .caret {
    color: @sb-green-bright;
  }
  .open .btn-int {
    background-color: @sb-gray-lighter;
  }
  .btn-int {
    .fa,
    .fas,
    .far,
    .fab {
      background: none !important;
    }
  }
  > .dropdown > .dropdown-menu {
    box-shadow:
      inset 0 1px 1px rgba(0, 0, 0, 0.075),
      0 0 5px rgba(141, 198, 63, 0.4);
    padding: 15px;
    right: auto;
    left: 0;
    border: 5px solid @sb-gray;
    margin: 0;
    min-width: 200px;
    > items > * {
      display: block !important;
      margin: 0 0 8px !important;
      &:last-child {
        margin: 0 !important;
      }
    }
    > items a {
      text-transform: none !important;
      color: @fps-text !important;
      font-weight: normal !important;
      line-height: inherit !important;
      font-size: inherit !important;
      &:hover,
      &:active {
        color: @sb-green-bright !important;
      }
      .fa,
      .fas,
      .far,
      .fab {
        margin-right: 7px;
      }
    }
  }
}

sb-alert-modal {
  .with-type {
    @border-size: 25px;
    position: relative;
    border-left: @border-size solid;
    &::before {
      content: '\F12A';
      font-family: 'Font Awesome 6 Free';
      font-weight: 900;
      font-size: 24px;
      display: block;
      height: @border-size;
      width: @border-size;
      color: #fff;
      text-align: center;
      position: absolute;
      left: -@border-size;
      top: 50%;
      margin-top: -(@border-size / 2);
    }
  }
  .with-type-danger {
    border-left-color: @sb-red;
  }
  .with-type-warning {
    border-left-color: @sb-yellow;
  }
  .with-type-info {
    border-left-color: @sb-blue;
    &::before {
      content: '\f129';
    }
  }
  .close {
    opacity: 1;
    color: @fps-text-secondary;
  }
  .sb-alert-buttons {
    margin-top: 25px;
  }
}
sb-alert-modal .sb-alert-buttons button,
.modal-footer button {
  margin-right: 5px;
  float: right;
}
.modal-footer-center {
  text-align: center;
  button {
    float: none;
    display: inline-block;
  }
}

.metadata-modal .modal-body {
  overflow-x: auto;
  width: 570px;
}

.confirm-modal h4.title {
  margin-top: 0;
}
/*** Confirmation Modal Styles ***/
.confirmation-modal .modal-footer .btn-default {
  border: none;
  color: @sb-white;
  background-color: @sb-gray;
}

.confirmation-modal .modal-footer .btn-default:hover,
.confirmation-modal .modal-footer .btn-default:active {
  background-color: @sb-green-bright;
}

/*** Back to dashboard/Data Room/etc link ***/
.backlink {
  text-transform: uppercase;
  color: @fps-text;
  font-weight: bold;
  font-size: 14px;
  float: right;
  text-decoration: none;

  .fa,
  .fas,
  .far,
  .fab {
    color: @sb-green-type;
    margin-right: 4px;
  }
}

.corporate-actions .backlink {
  margin-top: 23px;
}

.backlink-lower {
  margin-top: 28px;
}

.backlink-profile {
  margin-top: 7px;
}

.backlink-cap {
  margin-top: 4px;
}

.backlink:hover,
.backlink:active,
.backlink:focus {
  text-decoration: none;

  .fa,
  .fas,
  .far,
  .fab {
    color: @sb-green-bright;
  }
}

/*** user list widget ***/
.user-list-widget {
  padding-left: 0;
  list-style: none;
}

/* Slide Menu */
.sb-slide-menu(@menu-width) {
  @lip-width: 30px;
  .nav-bg {
    width: 100%;
    height: 100%;
    z-index: @z-index-5;
    position: fixed;
    top: 0;
    left: 0;
  }
  nav {
    display: flex;
    .generic-animation(0.2s);
    position: fixed;
    bottom: 0;
    top: 0;
    left: -@menu-width;
    height: 100%;
    width: auto;
    z-index: @z-index-5;

    /*    Containers    */
    .menu-container {
      display: flex;
      flex-direction: column;
      margin-top: @header-height;
      width: @menu-width;
      background: #fff;
      > header {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        padding: 5px;
        align-items: baseline;
        background: @fps-box-header-background;
        position: relative;
        width: 100%;
        width: @menu-width;

        h1 {
          margin: 5px;
          margin-right: auto;
        }
      }

      .nav-content {
        position: static;
        width: 100%;
        overflow-y: auto;

        .side {
          padding-top: 0px;

          .filters {
            position: relative;
            height: 90%;
            top: 0px;
            margin: 0px;
          }
        }
      }
    }
    .menu-lip {
      display: flex;
      align-items: center;
      width: @lip-width;
      padding-left: 4px;
      padding-right: 4px;
      background-color: fade(@sb-gray-light, 12%);

      &:hover {
        background-color: fade(@sb-gray-light, 36%);
      }
    }

    &.opened {
      left: 0;
      .menu-container {
        -webkit-box-shadow: 3px 0px 3px 0px rgba(0, 0, 0, 0.34);
        box-shadow: 3px 0px 3px 0px rgba(0, 0, 0, 0.34);
      }
    }

    /*    End Containers    */

    /*    Local Styling    */
    h4 {
      border-bottom: 1px solid @sb-gray;
      font-size: 25px;
      padding-bottom: 10px;
      margin: 0;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      padding-right: 35px;
      position: relative;
    }
  }
}

/*
 * Sometimes we need to hide form controls from user, however simple hiding
 * with display: none makes them unusable in selenium tests. This class will
 * hide controls off the screen, so they are technically "visible", but just
 * not rendered on screen.
 */
.hidden-off-screen {
  display: block !important;
  position: absolute !important;
  clip: rect(0px, 0px, 0px, 0px);
}

.back-button-info {
  position: relative;
  padding: 5px;
  margin: 20px 20px 0;
}

.abort-form {
  margin-bottom: 30px;
}

.trashicon {
  float: left;
  margin: 10px 15px 0px 0px;
}

sb-document-download {
  pointer-events: none;
  * {
    pointer-events: auto;
  }
  button[disabled] {
    color: @sb-gray !important;
  }
  .fa-spin {
    color: @sb-gray !important;
    padding: 0 !important;
    margin-right: 5px;
  }
}

.template-branding {
  font-size: 13px;
  text-transform: none;
  color: @fps-text-secondary;
}

.async-document-download {
  &:not(.btn-link) {
    .fa,
    .fas,
    .far,
    .fab {
      padding: 0;
      margin-right: 5px;
    }
  }
  .fa-spinner {
    color: @fps-text-secondary;
  }
}
.async-document-download-failed span {
  color: @sb-red;
}

h3 .async-document-download {
  background-color: @sb-gray;
  color: @sb-white;
}

h3 .async-document-download:hover {
  color: @sb-white;
}

.content-header-title {
  margin: 0 0 5px;
  font-size: 21px;
  font-weight: bold;
}

.content-header-info {
  margin-bottom: 20px;
}

.icon-link {
  color: inherit;
  i {
    margin-right: 6px;
  }
}

.icon-link:hover {
  color: inherit;
}

.icon-link {
  i.fa,
  i.fas,
  i.far,
  i.fab {
    color: @sb-green-type;
  }
}

.icon-link.disabled {
  color: #90a7a7 !important;
}

.has-error .form-dropdown {
  border-color: @sb-red-type;
}

.form-dropdown.open {
  /*
   * This gets the ul in the
   * right stacking group.
   * The style below ensures visibility.
   */
  z-index: @z-index-9 !important;
}
.form-dropdown.open ul,
.form-dropdown.open div {
  z-index: @z-index-10 !important;
}

.collapse .form-dropdown ul {
  margin-bottom: 50px;
}

.modal-body .panel-heading {
  .checkradios-radio {
    margin-right: 8px;
  }
}

.panel-no-body {
  .panel-body {
    display: none;
  }
}

.no-border {
  border-style: none !important;
}

.panel-disabled {
  > .panel-heading {
    color: @fps-text-secondary;
    a {
      cursor: default;
      text-decoration: none;
    }
  }
}

.collapse {
  overflow: hidden;
}

/* Allow overflow when panel is open (for dropdowns). */
.collapse.in,
.panel-group .panel {
  overflow: visible;
}

.section-label {
  text-transform: uppercase;
  color: @fps-text-secondary;
  font-size: 12px;
}

.section-help {
  margin-left: 3px;
}

.bare-layout {
  background: #fff;
  .container {
    padding: 0;
    width: 100%;
  }
}

[data-hide-until-bootstrap-ng2] {
  opacity: 0;
}

#wrap {
  margin-top: @header-height;
  margin-bottom: @footer-height;
  margin-left: auto;
  margin-right: auto;
}

.navbar-inner {
  height: @header-height;
  border-bottom: 5px solid #6fa4e6;

  .container {
    height: 100%;
  }
}

/* Set the fixed height of the footer here */
#push,
body > footer {
  height: @footer-height;
}

body > footer {
  text-align: center;
  color: @fps-text;
  background-color: @sb-gray;
  font-size: 9pt;

  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;

  z-index: @z-index-4;
  > .content {
    padding: 3px;

    a {
      color: @sb-white;
    }
  }
}

/*replace the footer with the simulator box*/
/*the problem is that the recording version is too big*/
body > footer > #simulator-box {
  padding: 3px;
  background-color: @sb-gray-light;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: @footer-height;
  z-index: 3;
}

body > footer .links {
  margin-left: 50px;
  white-space-collapse: discard;
  float: left;
}

body > footer .links a {
  display: inline-block;
  margin: 0;
  padding: 0 10px;
  border-right: 2px solid @sb-gray-light;
  line-height: 9pt;
}

body > footer .links a:last-child {
  border-right: 0;
}

body > footer span.copyright {
  display: inline-block;
  white-space-collapse: discard;
  margin: 0;
  padding: 0 14px;
  border-right: 2px solid @sb-gray-light;
  line-height: 9pt;
}

#entity-logo {
  height: 42px;
  padding: 0px 20px 0px 00px;
  margin: 0;
  float: left;
}

#entity-logo img {
  margin-top: 3px;
  height: 40px;
  padding-left: 10px;
}

#entity-logo.text a {
  display: inline-block;
  font-size: 24px;
  font-weight: bold;
  margin-top: 10px;
}

.navbar-partner-brand {
  display: inline-block;
  margin: 4px 0 4px 5px;
  padding: 1px 0 1px 15px;
  border: 0;
  border-left: 1px solid @sb-gray;
  height: 30;
  img {
    height: 30px;
  }
}

#page-title {
  color: #444;
  margin: 10px 0;
  padding: 5px 10px;
  border-left: 1px solid #999;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 430px;
  line-height: 20px;
  font-size: 130%;
  position: absolute;
  left: 600px;
}

.navbar-inner .container {
  position: relative;
}

.navbar .container {
  display: flex;
  flex-direction: row;
  align-items: center;

  .navbar-nav {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .left-header {
    display: flex;
    flex-direction: row;
    margin-right: auto;
  }
}

@media (min-width: 768px) {
  #page-title {
    width: 190px;
    left: 400px;
  }
}

@media (min-width: 1024px) {
  #page-title {
    width: 360px;
    left: 450px;
  }
}

@media (min-width: 1280px) {
  #page-title {
    width: 460px;
    left: 550px;
  }
}

@media (max-width: 767px) {
  #page-title {
    position: static;
    border-left: none;
  }

  .navbar-nav .open .dropdown-menu {
    position: absolute;
    background-color: white;
    border: 5px solid @sb-gray;
  }
}

@media only screen and (max-device-width: 425px) {
  .navbar-inner > .container {
    .dropdown {
      display: none;
    }

    .nav-dropdown .dropdown-menu {
      max-width: 240px;

      sb-msg-notification,
      sb-wi-notification {
        > div.notification-content {
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }
      }
    }
  }
}

#user-status-info {
  display: inline-block;
  vertical-align: top;
}

#user-status-info .num-tasks {
  position: relative;
  top: -8px;
  left: 21px;
  background-color: @sb-orange;
  border-radius: 2px;
  padding: 0px 3px 1px 2px;
}

#alert {
  display: inline-block;
  width: 28px;
  height: 28px;
  border-radius: 14px;
  background-color: @sb-gray;
  color: @sb-gray-darker;
  position: relative;
  margin-top: 1px;
}

li.open #alert {
  background-color: #fff;
}

sb-alert-banner {
  display: block;
}

#alert i {
  /* Absolute position will not thicken the navbar due to bigger font size of an
  /* icon */
  position: absolute;
  left: 5px;
  top: 5px;
  font-size: 19px;
}

/* Used to set the height of the avatar menu option. */
.navbar-nav > li.iconlike > a {
  padding-top: 5px;
  padding-bottom: 5px;
}

#content {
  background-color: #fff;
}
.normal-content-padding {
  padding: 0 15px;
}

#content sbx-page-header {
  padding-left: 15px;
  padding-right: 15px;
  background-color: #fff;

  &::after {
    left: 0;
    right: 0;
  }
}

#content sbx-page-header + div {
  margin-top: -15px;
}
.sbx-header-actions {
  display: inline-block;
  margin-left: 10px;
}
/* -----[ Data table ]------------------------------------------------------ */

table.table th.sorted-on.ascending:before {
  float: right;
  -webkit-font-smoothing: antialiased;
  content: ' \2193';
}

table.table th.sorted-on.descending:before {
  float: right;
  -webkit-font-smoothing: antialiased;
  content: ' \2191';
}

/* -----[ Forms ]----------------------------------------------------------- */
.offset-form-group .options > .option > .form-group {
  > label:before {
    float: left;
    width: 17px;
    content: '\21b3';
  }

  > label > div {
    margin-left: 17px;
  }
  .col-md-3 {
    width: 23%;
    margin-left: 2%;
  }
}

.no-field-separators .form-group {
  border-top: 0;
}

/* Hide error details in alert block */
.alert ul.errors {
  display: none;
}

div.currency input.form-control {
  width: 160px;
  text-align: right;
}

div.currency.hourly-wage input.form-control {
  width: 105px;
  text-align: right;
}

.input-group .form-control.currency-field,
div.currency input {
  width: 171px;
  text-align: right;
}

div.currency.input-append input {
  width: 132px;
}

div.measurement {
  width: 164px !important;
}

div.measurement input {
  width: 164px !important;
  text-align: right;
}

div.measurement.input-append input {
  width: 132px;
}

.fileupload-new,
.fileupload-exists {
  /* Simulate .glyphicons, but without the relative position call, since that */
  /* seems to mask the click event. */
  font-family: 'Glyphicons Halflings';
  padding-top: 1px;
}

// Element, that defines bounds for element, that should always be visible,
// when input control is focused. Bounds are bigger than element itself to make
// it visible behind fixed UI elements, like buttons at the bottom, or header
// at the top.  .visible-bounds elements are inserted dynamically when element
// gets focus and removed after scrolling is completed.
.visible-bounds {
  @upper-margin: @header-height + @wi-title-height + @outline-height + 10;
  @lower-margin: @footer-height + @lower-buttons-height + 60;

  position: absolute;
  margin-top: @upper-margin;
  // HACK: abuse width element to specify upper margin, that can be easily
  // extracted by javascript. Otherwise, width is irrelevant for rendering.
  width: @upper-margin;
  margin-bottom: @lower-margin;
  height: 40px;
}

#email_content {
  display: none;
  label {
    cursor: default;
  }
}

#new_email {
  label {
    cursor: default;
  }
}

/* -----[ user status ]----------------------------------------------------- */

div#user-status {
  width: 350px;
  padding: 0;
}

#user-status img.picture {
  width: 80px;
  margin-right: 10px;
  float: left;
  .grayscale();
}

#user-status .info .name {
  font-size: 120%;
  font-weight: bold;
}

#user-status .info .login {
  color: @fps-text-secondary;
  font-size: 80%;
  margin-bottom: 10px;
}

#user-status .actions {
  margin-top: 5px;
}

.user-dashboard .task {
  margin-bottom: 6pt;
}

.sig-code-finder * {
  float: left;
}

.sig-code-finder button {
  margin-right: 3px;
}

/* -----[ context menu ]---------------------------------------------------- */

.bs-docs-sidenav {
  width: 160px;
  margin: 30px 0 0;
  padding: 0;
  background-color: #fff;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
  -webkit-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.065);
  -moz-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.065);
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.065);
}

.bs-docs-sidenav > li > a {
  display: block;
  width: 190px \9;
  margin: 0 0 -1px;
  padding: 8px 14px;
  border: 1px solid #e5e5e5;
}

.bs-docs-sidenav > li:first-child > a {
  -webkit-border-radius: 6px 6px 0 0;
  -moz-border-radius: 6px 6px 0 0;
  border-radius: 6px 6px 0 0;
}

.bs-docs-sidenav > li:last-child > a {
  -webkit-border-radius: 0 0 6px 6px;
  -moz-border-radius: 0 0 6px 6px;
  border-radius: 0 0 6px 6px;
}

.bs-docs-sidenav > li:first-child:last-child > a {
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
}

.bs-docs-sidenav > .active > a {
  position: relative;
  z-index: 2;
  padding: 9px 15px;
  border: 0;
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.15);
  -webkit-box-shadow:
    inset 1px 0 0 rgba(0, 0, 0, 0.1),
    inset -1px 0 0 rgba(0, 0, 0, 0.1);
  -moz-box-shadow:
    inset 1px 0 0 rgba(0, 0, 0, 0.1),
    inset -1px 0 0 rgba(0, 0, 0, 0.1);
  box-shadow:
    inset 1px 0 0 rgba(0, 0, 0, 0.1),
    inset -1px 0 0 rgba(0, 0, 0, 0.1);
}

/* Chevrons */
.bs-docs-sidenav .fa-chevron-right {
  float: right;
  margin-top: 2px;
  margin-right: -6px;
  opacity: 0.25;
}

.bs-docs-sidenav > li > a:hover {
  background-color: #f5f5f5;
}

.bs-docs-sidenav a:hover .fa-chevron-right {
  opacity: 0.5;
}

.bs-docs-sidenav .active .fa-chevron-right,
.bs-docs-sidenav .active a:hover .fa-chevron-right {
  opacity: 1;
}

.bs-docs-sidenav.affix {
  top: 40px;
}

.bs-docs-sidenav.affix-bottom {
  position: absolute;
  top: auto;
  bottom: 270px;
}

/* -----[ Document Tree Workitem ]--------------------------------------------------- */
.protected-tree {
  .forms {
    font-size: 1em;
    padding-left: 25px;
    .sbf-shoobx-generated {
      color: @fps-text-secondary;
    }
  }
}
/* -----[ Document Tree Workitem ]--------------------------------------------------- */

aside .folders {
  padding-top: 10px;
}

.folders li:not(:last-child)::after {
  content: '>';
  padding-left: 3px;
}

#documents_access {
  font-size: 15px;
  line-height: 18px;
}
sb-multi-select-tree-listing,
sb-single-select-tree-listing,
sb-single-select-any-tree-listing {
  display: block;
  padding-top: 10px;
  sb-multi-select-tree-listing {
    padding-left: 28px;
  }
  h4 {
    margin-left: -16px;
  }
  i.toggle-branch {
    margin-left: -13px;
    padding-right: 5px;
  }
  i.toggle-branch.fa-caret-down {
    padding-right: 5px;
    margin-left: -16px;
  }
  label {
    margin: 0;
    &.disabled {
      color: @fps-text-secondary;
    }
  }
  .permission-description {
    padding-left: 28px;
    display: block;
    color: @fps-text-secondary;
    &.disabled {
      opacity: 0.3;
      filter: alpha(opacity=30);
    }
  }
}

/* -----[ Workitems ]------------------------------------------------------ */

form .compact .control-label {
  width: 100px;
  text-align: left;
}

form .compact .controls {
  margin-left: 0px;
}

/* -----[ Icons ]--------------------------------------------------------- */

.fa-status-success {
  color: @sb-green;
}

.fa-status-warning {
  color: @sb-yellow;
}

.fa-status-important {
  color: @sb-red;
}

.fa-status-info {
  color: @sb-blue;
}

/* -----[ Dashboard ]------------------------------------------------------ */

#wrap > .dashboard.container {
  padding-top: 20px;

  @media (max-width: @screen-xs-max) {
    padding-top: 55px;
  }
}

div.dashboard-row {
  padding-bottom: 20px;
}

div.dashboard-row:last-child {
  padding-bottom: 5px; /* Needed for shadow */
}

div.dashboard-outer-panel {
  padding-left: 10px;
  padding-right: 10px;
}

div.dashboard-outer-panel:first-child {
  margin-left: 0px;
  padding-left: 0px;
}

div.dashboard-outer-panel:last-child {
  margin-right: 0px;
  padding-right: 0px;
}

@media (max-width: 969px) {
  div.dashboard-outer-panel {
    margin: 20px 0 0;
    padding: 0;
  }
  div.dashboard-outer-panel:first-child {
    margin: 0;
  }
}

/*--------[ Charting Styles ]----------------------------------------*/
.sb-bb-tooltip {
  background: rgba(255, 255, 255, 0.9);
  border-radius: 2px;
  padding: 10px;
  border: 2px solid #999;
  box-shadow: 2px 3px 3px 0 rgba(0, 0, 0, 0.25);
  table {
    width: 100%;
  }
  td {
    padding: 0 0 7px 7px;
  }
  td:last-child {
    padding-right: 0;
    text-align: right;
    font-weight: bold;
  }

  tr:last-child td {
    padding-bottom: 0;
  }
}
.bb-tooltip-container {
  z-index: @z-index-6;
}
.bb-areas path {
  opacity: 1;
}
.bb-axis {
  .tick text {
    fill: @fps-text;
  }
  .tick line,
  path {
    stroke: @sb-gray;
  }
}
.bb-legend-item text,
.bb-axis-x-label,
.bb-axis-y-label {
  font-family: 'Source Sans Pro', Helvetica, Arial, sans-serif !important;
}
.bb-legend-item text {
  fill: @fps-text;
  font-size: 13px;
}
.bb-axis-x-label,
.bb-axis-y-label {
  fill: @sb-gray;
}

.bb {
  .sbx-today-marker line {
    stroke: @fps-text;
    stroke-width: 4px;
    pointer-events: none;
  }

  .sbx-today-marker text {
    fill: @fps-text;
    font-size: 10pt;
    pointer-events: none;
  }
}

#equity-grants-chart,
#investments-chart,
#warrants-chart {
  .bb-circle {
    display: none;
  }
  .circle-today {
    fill: @sb-orange !important;
  }
  .bb-circle.circle-today,
  .bb-circle._expanded_ {
    display: block;
  }
  .bb-axis-x-label,
  .bb-axis-y-label {
    font-weight: bold;
    font-size: 15px;
    fill: @fps-text;
  }
  /* Do not display bounding lines for areas: if displayed, 0-area chart will
   * appear as a single-pixel line, which might be misleading.
   */
  .bb-chart-line {
    .bb-lines {
      display: none;
    }
  }
}

#shares-chart {
  height: 170px;
}

div.dashboard-panel {
  height: 270px;
  padding: 0px;
  margin: 0;
  border: 0;
  background: @sb-white;
  font-size: 90%;

  > div:only-of-type {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
  }
}

div.dashboard-panel .header {
  font-size: 16px;
  font-weight: 800;
  color: @sb-green-type;
  margin-top: 5px;
  margin-bottom: 5px;
  text-transform: uppercase;
  line-height: 1em;
}

div.dashboard-panel .anchorless-dashboard-header {
  display: block;
  line-height: 1em;
  vertical-align: top;
  padding-left: 15px;
  padding-bottom: 3px;
  padding-top: 3px;
}

div.dashboard-panel .header a {
  display: block;
  color: inherit;
  line-height: 1em;
  vertical-align: top;
  padding-left: 15px;
  padding-bottom: 3px;
  padding-top: 3px;
}

div.dashboard-panel .header span {
  display: block;
  padding-left: 15px;
  padding-bottom: 3px;
  padding-top: 3px;
}

div.dashboard-panel .content {
  flex: 1;
  height: 208px;
  overflow: auto;
  padding: 0px 15px;
}

div.dashboard-panel .entity_checklist {
  height: auto;
  h5 {
    margin-top: 0;
  }
}

div.dashboard-panel .entity-checklist-scroll {
  height: 120px;
  .company-setup-list {
    width: auto;
    li {
      height: 35px;
      width: 75%;
      margin-left: 12.5%;
    }
    li:before {
      left: -26px;
    }
  }
}

div.dashboard-panel .employee_checklist h5 {
  margin-top: 0;
}

div.dashboard-panel .employee-checklist-scroll {
  height: 170px;
  .company-setup-list {
    width: auto;
    margin: 0;
    li {
      height: 35px;
      width: 85%;
      margin: 0 0 0 7.5%;
    }
    li:before {
      display: none;
    }
  }
}

div.dashboard-panel ul {
  padding-left: 0;
}

div.dashboard-panel .actions {
  padding: 3px 15px;
  border-top: 1px solid #e1e1e1;
  height: 30px;
  color: #333;
  font-weight: 600;
  text-overflow: ellipsis;
  white-space: nowrap;
}

div.dashboard-panel .actions .input-xs {
  color: #90a7a7;
  height: 22px;
  margin: 0;
}

div.dashboard-panel .actions .btn.btn-xs:last-child {
  margin-right: 0 !important;
}

div.dashboard-panel .header input[placeholder],
div.dashboard-panel .actions input[placeholder] {
  font-style: italic;
  font-weight: 400;
}

div.dashboard-panel .actions a:not(.btn) {
  display: inline-block;
  color: inherit;
  font-weight: 600;
  font-size: 95%;
  line-height: 20px;
  text-transform: uppercase;
  margin-right: 10px;
  margin-top: 2px;

  i.fa,
  i.fas,
  i.far,
  i.fab {
    color: @sb-green-type;
  }
}

div.dashboard-panel .actions a:not(.btn):hover {
  color: @sb-green-bright;

  i.fa,
  i.fas,
  i.far,
  i.fab {
    color: @sb-green-bright;
  }
}

div.dashboard-panel .actions a.disabled,
div.dashboard-panel .actions a.disabled i.fa,
div.dashboard-panel .actions a.disabled i.fas,
div.dashboard-panel .actions a.disabled i.far,
div.dashboard-panel .actions a.disabled i.fab,
div.dashboard-panel .actions .zero-actions-panel-link,
div.dashboard-panel .actions .zero-actions-panel-link i.fa,
div.dashboard-panel .actions .zero-actions-panel-link i.fas,
div.dashboard-panel .actions .zero-actions-panel-link i.far,
div.dashboard-panel .actions .zero-actions-panel-link i.fab,
div.dashboard-panel .actions a[data-amount='0'],
div.dashboard-panel .actions a[data-amount='0'] i.fa,
div.dashboard-panel .actions a[data-amount='0'] i.fas,
div.dashboard-panel .actions a[data-amount='0'] i.far,
div.dashboard-panel .actions a[data-amount='0'] i.fab {
  color: #90a7a7;
}
.zero-actions-panel-link {
  text-transform: uppercase;
}

div.dashboard-panel .inline-actions {
  margin: 6px 0px 18px 0px;
}

div.dashboard-panel .actions .dropdown-toggle {
  border-radius: 0;
  font-weight: 600;
  color: #333;
}

ul.panel-list {
  margin-left: 0;
}

*[sb-scrollable] > ul.panel-list {
  padding-left: 15px !important;
}

ul.panel-list li {
  list-style: none;
  line-height: 1.2em;
  margin-bottom: 10px;
}

ul.panel-list li:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
}

ul.panel-list li .item-icon {
  float: left;
  font-size: 16px;
}

ul.panel-list li .item-avatar {
  float: left;
  font-size: 16px;
}

ul.panel-list li .item-details {
  padding-left: 15px;
  overflow: auto;
}

ul.panel-list li.no-icon .item-details {
  padding-left: 0px;
}

ul.panel-list li .item-details .item-title {
  font-weight: bold;
  font-size: 16px;
}

ul.panel-list li .item-details > div {
  font-size: 14px;
}

ul.panel-list li .item-details .item-actions {
  margin-top: 3px;
}

ul.panel-list li .minor {
  font-size: 12px !important;
  line-height: 1.5em;
  color: #90a7a7;
}

ul.panel-list li div.one-line {
  height: 1.3em;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

ul.panel-data-list {
  margin-left: 0;
  margin-bottom: 0;
}

ul.panel-data-list li {
  list-style: none;
  line-height: 24px;
  padding: 0px 10px;
}

div.dashboard-panel {
  .panel-text-label {
    font-size: 13px;
    font-weight: 500;
    color: @fps-text-secondary;
    text-transform: uppercase;
  }

  .panel-text-value {
    font-size: 16px;
    font-weight: 700;
    color: @fps-text;
    line-height: 16px;
  }

  .panel-text-info {
    font-size: 13px;
    font-weight: 500;
    color: @fps-text-secondary;
    font-style: italic;
  }
}

ul.panel-data-list li:nth-child(2n + 1) {
  background: #eff2f2;
}

.financing-panel {
  overflow: hidden;
  width: 99%;
}

.financing-panel-chart {
  height: 128px;
}

.investments-panel-chart {
  height: 90px;
}

.equity-pie-tooltip .hide {
  display: none;
}

.has-non-converts {
  font-size: 80%;
  color: @fps-text-secondary;
  margin-left: 15px;
}

.dashboard-panel .sbf-document-pending {
  font-size: 12px;
}

/* ----[ Finance App ]---------------------------------------------- */
.bank-info td {
  padding-left: 5px;
}

.banking {
  .account-selector > .form-dropdown {
    margin-right: 30px;
  }

  img.bank-logo {
    float: left;
    margin: 10px 15px 0px 0px;
    height: 50px;
  }

  .date-range-row {
    margin: 20px 0px;

    > div {
      display: inline-block;
      padding: 6px 0px 6px 0px;
      vertical-align: middle;
    }

    .filter-label {
      text-transform: uppercase;
      color: @fps-text-secondary;
      margin-top: 5px;
      margin-left: 48px;
      margin-right: -48px;
    }
  }
}

/* -----[ File Upload Widget (AJAX version) ]---------------------- */

sb-multi-file-upload {
  @padding: 20px;
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 4px dashed @sb-gray-light;
  border-radius: 5px;
  padding: @padding 0;
  > * {
    padding: 0 @padding;
  }
  input {
    height: 0;
    width: 0;
    overflow: hidden;
  }
  form {
    margin: 0 auto;
    text-align: center;
  }
  label {
    margin: 0;
    display: block;
    font-size: 22px;
    strong {
      color: @sb-green-type;
    }
    &:hover strong {
      color: @sb-green-bright;
    }
  }
  ul.list-unstyled {
    margin: @padding 0 0;
    width: 100%;
    overflow: auto;
    padding: 0 @padding;
  }
  li {
    display: flex;
    padding: 10px;
    border: 1px solid @sb-gray-light;
    border-bottom: 0;

    &:last-child {
      border: 1px solid @sb-gray-light;
    }

    .fa,
    .fas,
    .far,
    .fab {
      font-size: 22px;
    }

    .fa-file-alt {
      color: @fps-text-secondary;
      margin-right: 10px;
    }

    .fa-check-circle {
      color: @sb-green;
    }

    .fa-sync {
      -webkit-animation: rotation 1s infinite linear;
    }

    // Hack to hide icon when status changes (it shows both icons for a short
    // time otherwise
    &.text-danger,
    &.completed {
      .fa-sync {
        display: none;
      }
    }

    &.not-started {
      color: @fps-text-secondary;
      font-style: italic;
    }

    .upload-status {
      margin-left: auto;
    }

    .size-limit-error {
      width: 94%;
      top: 23px;
      left: 3%;
      position: absolute;
    }
  }

  .dnd-info {
    display: flex;
    color: @fps-text-secondary;

    .fa-upload {
      font-size: 96px;
      margin-right: 30px;
      display: block;
    }

    .dnd-label {
      display: block;
      font-size: 48px;
      line-height: 48px;
      margin-bottom: 15px;
    }
  }
}

.angular-file-upload {
  @widget-height: 33px;
  @control-width: 50px;
  .sb-widget &.input-body > * {
    flex: 1;
  }
  .widget {
    background: @sb-gray-light;
    width: 100%;
    padding: 1px @control-width 1px 1px;
    height: @widget-height;
    overflow: hidden;
    position: relative;
    border-radius: 1px;
    .filename {
      background: #fff;
      display: block;
      height: 100%;
      position: relative;
      padding: 5px 30px 4px 15px;
      overflow: hidden;
      &.no-file {
        color: @fps-text-secondary;
      }
      .preview {
        .reveal-animation(0.2s);
        color: @sb-green;
        position: absolute;
        top: 3px;
        right: 3px;
      }
      .download {
        .reveal-animation(0.2s);
        color: @sb-green;
        position: absolute;
        top: 3px;
        right: 7px;
      }
    }
    .control {
      position: absolute;
      right: 0;
      top: 0;
      height: @widget-height;
      width: @control-width;
      > div {
        height: 100%;
        width: 100%;
        text-align: center;
      }
      .fa,
      .fas,
      .far,
      .fab {
        margin-top: 8px;
      }
      .btn .fa,
      .btn .fas,
      .btn .far,
      .btn .fab {
        margin-top: 2px;
      }
      .fileinput-button {
        overflow: visible;
      }
    } // end .control
  } // end .widget
  .widget.sb-widget-error {
    background: @sb-red-type;
    .filename {
      color: @sb-red-type;
    }
  }
  .loading-bar-wrap {
    width: 100%;
    height: 3px;
    position: relative;
  }
  .loading-bar {
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 100%;
    background: @sb-green;
    .generic-animation(1.5s);
    &.ng-hide {
      opacity: 0;
    }
  }
} // end .angular-file-upload

.upload-widget.disabled > .files {
  opacity: 0.5;
}

.files > p {
  padding: 1px;
}

/* -----[ AJAXy tables ]------------------------------------------- */

table.loading {
  opacity: 0.5;
}

table.load-failed {
  opacity: 0.5;
}

tr.batch td {
  text-align: center;
}

tr.batch td > ul.pagination {
  margin: 0;
}

/* -----[ ??? ]---------------------------------------------------- */

.context-menu {
  padding: 10px 0;
}

.table th a {
  color: #000;
}

.errors div.error {
  display: inline;
}

div.download {
  font-size: 120%;
  font-weight: bold;
  color: #ffa600;
}

.button-menu-item {
  text-align: center;
}

.button-menu-item a {
  color: #555;
  text-shadow: 0px 2px 2px #ccc;
  padding: 20px 0;
}
.button-menu-item a:hover {
  text-decoration: none;
  color: #0088cc;
}

#login .form-actions {
  text-align: right;
}

#login .new-account {
  font-size: 16px;
  text-transform: none;
  float: right;
  font-weight: normal;
}

#termination_form {
  .option label,
  textarea {
    margin-left: 25px;
  }

  .boarder {
    border: 1px solid @sb-gray-light;
  }

  input {
    width: 85%;
  }
}

/* -----[ Workitem usertable modal ]-------------------------- */

.usertable-address-enabled .usertable-existing {
  padding: 32px 0px 0px 0px;
}

.new-user-extra-form {
  padding: 18px 0px 0px 0px;
}

.usertable-existing-enabled .new-user-extra-form {
  padding: 16px 0px 0px 0px;
}

.new-user-extra-form .form-group:first-child {
  border-top: 2px solid @fps-box-header-background;
}

.usertable-existing-enabled .user-form-group .form-group {
  > label:before {
    float: left;
    width: 17px;
    content: '\21b3';
  }
  > label {
    padding-left: 0px;
  }
}

.user-form-group .viewspace {
  padding: 0px;
}

/* -----[ Workitem: Select or Create Stakeholder ]-------------------------- */

.select-or-create-stakeholder .option {
  margin-bottom: 5px;
}

.select-shareholder-message-spacing {
  padding-left: 15px;
  padding-right: 15px;
}

.select-shareholder-message-spacing {
  padding-left: 15px;
  padding-right: 15px;
}

.select-shareholder-form-notification-spacing {
  padding-left: 30px;
  padding-right: 30px;
}

.existing-user-label {
  margin-bottom: 8px;
}

.existing-user-form {
  .col-lg-4 {
    width: 34%;
  }
}

.selectform {
  .col-md-3 {
    padding-left: 0;
  }
}

.new-user-form {
  .col-md-3 {
    padding-left: 80px;
  }
  .col-md-9 {
    width: 34%;
  }
}

.offset-form-group .options > .option > .form-group {
  > div.standalone:before {
    float: left;
    width: 17px;
    font-weight: bold;
    content: '\21b3';
  }

  > div.standalone > div {
    margin-left: 17px;
  }
  .standalone {
    width: 34%;
    padding-left: 80px;
    margin-left: 2%;
  }
}

/* -----[ Workitem: Create/Upload Shareholder Communication ]--------------- */

#consents label {
  display: inline;
  font-weight: normal;
  margin: 0;
}

/* -----[ Workitem: Add And Manage Subprocesses ] -------------------------- */

.redirected > td {
  color: @sb-gray !important;
}

/* -----[ Workitem: Select Document Source ]-------------------------------- */

.resign.inactive {
  color: #aaa;
}

.resign.inactive .label-info {
  background: #ddd;
  color: #fafafa;
  text-shadow: none;
}

/* -----[ Workitem: Collect Document Metadata ]---------------------------- */

#collect-metadata {
  .label {
    background-color: transparent;
    color: @fps-text;
    font-size: 16px;
    margin: 0;
    padding: 0 0 5px 14px;
    display: block;
    text-align: left;

    label {
      font-weight: 800;
    }
  }
}

.edit-form {
  margin-bottom: 0;
}

/* Overriding bootstrap.css */
#collect-metadata.form-horizontal .control-label {
  width: 220px;
  margin-right: 10px;
}

#collect-metadata.form-horizontal .controls {
  margin-left: 224px;
}

#collect-metadata #accordion {
  margin-top: 10px;
}

/* -----[ Workitem: Collect Meeting Key Data ]------------------------------ */

.collect-meeting-keydata .input-row {
  margin-bottom: 10px;
}

/* -----[ Workitem: Record Resolutions ]------------------------------------ */

.record-resolutions #form-widgets-meeting_date-row {
  width: 75%;
}

/* -----[ Workitem: Select Approval ]------------------------------------ */

.approvals-margin {
  margin-left: 45px;
}

/* -----[ specifics ]------------------------------------------------------- */

div.user-profile {
  padding: 10px 0;
}

.dashboard-panel .user-profile {
  padding: 0;
}

div.user-profile .avatar {
  margin: 0 10px;
  float: left;
}

div.user-profile .avatar .avatar-image {
  width: 80px;
  height: 80px;
  border-radius: 40px;
  .grayscale();
}

div.user-profile .avatar .avatar-initials {
  display: table-cell;
  width: 80px;
  height: 80px;
  border-radius: 40px;
  color: @sb-gray-darker;
  background: @sb-gray-lighter;
  font-size: 38px;
  font-weight: 200;
}

div.user-profile .info {
  padding: 0 10px;
}

div.user-profile .actions {
  text-align: right;
  padding: 0px 10px 0px 10px;
}

div.user-profile .info .name {
  font-weight: bold;
  font-size: 120%;
}

div.user-profile .info .login {
  color: @fps-text-secondary;
  font-size: 90%;
}

div.user-profile .info .roles {
  line-height: 1.2em;
  margin-top: 5px;
}

div.user-profile .more-info {
  line-height: 1.2em;
  padding: 10px;
  clear: both;
}

div.user-profile .more-info .contact {
  margin-top: 5px;
}

div.group-title {
  font-size: 100%;
  font-weight: bold;
  margin: 25px 0 5px;
}

div.group-title .group-title-status {
  text-transform: uppercase;
  font-size: 12px;
  color: @fps-text-secondary;
  line-height: 0.9em;
}

.term-divider {
  margin: 10px 0;
  border-bottom: 2px solid @sb-gray-lighter;
  clear: both;
}

#pdf-preview-popup .modal-dialog {
  width: 800px;
}

.pdf-preview-toolbar {
  padding: 3px 6pt;
  background: #eee;
  border: 1px solid #ccc;
  border-bottom: 0;
}

.context-bar {
  height: 100%;
}

.sidebar,
.context-bar {
  dl,
  .dl-context {
    border-left: 5px solid @fps-text;
    background-color: @fps-box-background;
    padding: 0 10px 10px;
    margin-bottom: 0;
  }

  dl:first-child,
  .dl-context:first-child {
    padding-top: 10px;
    margin-top: 15px;
  }

  dt,
  .dt-context {
    font-weight: normal;
    text-transform: uppercase;
    font-size: 12px;
    color: @fps-text-secondary;
    line-height: 0.9em;
  }

  .dt-context {
    line-height: 1em;
  }

  dd {
    line-height: 1em;
    font-weight: 600;
    font-size: 16px;
    color: @fps-text;
  }
}

#activity-groups ol {
  padding-left: 0;
  font-size: 110%;
  font-weight: bold;
  line-height: 1.2em;
  list-style: none;
}

#activity-groups ol li {
  padding: 0.3em;
  margin-bottom: 0.3em;
  border-radius: 5px;
}

#activity-groups ol li.active {
  background: #428bca;
  color: white;
}

/* Signature dialog styles */
#signature-capture-canvas {
  color: #006;
  margin-bottom: 10px;
  border: 1px solid white;
  position: absolute;
  height: inherit;
  top: 0;
  left: 0;
  z-index: 1;
}

.canvas-wrapper {
  position: relative;
  height: 130px;
  max-width: 522px;
  background-color: #e5e5e5;
  margin-left: auto;
  margin-right: auto;

  hr {
    border-width: 2px;
    width: 85%;
    border-color: @sb-gray;
    position: absolute;
    top: 63%;
    left: 7.5%;
  }
}

#signature-upload-preview {
  max-width: 480px;
  max-height: 150px;
}
.signature-type-sample {
  height: 100px;
  width: 250px;
  display: inline-block;
}

.signature-type-image {
  height: 100px;
  max-width: 220px;
}

/* radio vs image vertical align hacks */
span.signature-type-helper {
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}

span.signature-type-radio {
  display: inline-block;
  vertical-align: middle;
}

.modal > form {
  /* Form immediately in a modal should not interfere with the layout */
  margin: 0;
}

/* Avatar editor styles */

#avatared-popup .modal-dialog {
  padding-top: 0;
  width: 500px;
}

#avatared-popup .modal-body {
  max-height: 490px;
}

/* -----[ Dashboard Panel: Company Setup ]---------------------------------- */

.company-profile .content {
  overflow: hidden !important;
}

.company-profile h5 {
  font-family: 'Source Sans Pro', Helvetica, Arial, sans-serif;
  font-size: 23px;
  font-weight: 300;
  color: @fps-text;
  float: left;
  text-align: center;
  width: 30%;
  margin-top: 17px;
}

@media (max-width: 1179px) {
  .company-profile h5 {
    margin-top: 3px;
  }
}

.company-profile-desc {
  color: @fps-text-secondary;
  line-height: 1.3em;
  font-size: 13px;
  .initial-company-setup & {
    margin-left: 33%;
    padding-left: 3%;
    border-left: 3px dotted @sb-gray-light;
  }
}

.company-setup-list {
  clear: both;
  list-style: none;
  color: @fps-text-secondary;
  font-size: 20px;
  padding: 0;
  margin: 0 auto;
  width: 85%;

  li span.btn {
    cursor: auto;
  }

  /* Default style */
  li span.btn:hover,
  li span.btn:focus,
  li span.btn:active {
    box-shadow: none;
    background-color: @sb-white;
    border-color: @sb-gray-light;
  }

  /* Ready to start */
  li.available a.btn {
    background-color: @sb-green-type;
    border: none;
    color: @sb-white;
  }

  li.in-progress span.btn,
  li.in-progress a.btn {
    background-color: @sb-gray-light;
  }

  li.completed span.btn {
    background-color: @sb-gray-light;
    color: @sb-white;
  }

  li {
    counter-increment: customlistcounter;
    width: 100%;
    position: relative;
  }

  li:before {
    content: counter(customlistcounter);
    float: left;
    font-family: 'Source Sans Pro', Helvetica, Arial, sans-serif;
    font-weight: 300;
    font-size: 26px;
    position: absolute;
    left: -18px;
    top: -3px;
  }

  span,
  a {
    font-size: 15px;
    width: 100%;
  }

  li.completed span:after {
    content: '\f00c';
    font-family: 'Font Awesome 6 Free';
    font-weight: 900;
    color: @sb-green;
    text-decoration: none;
    float: right;
    display: block;
    right: -20px;
    top: 9px;
    position: absolute;
  }
}

.company-setup-list:first-child {
  counter-reset: customlistcounter;
}

/* -----[ Dashboard Panel: Data Room ]-------------------------------------- */

.data-room-panel .content {
  font-size: 14px;

  ul.panel-data-list li {
    line-height: 140%;
    padding: 3px 10px;
    a.icon-link {
      text-decoration: none;
      display: flex;
      justify-content: space-between;
      i.fa,
      i.fas,
      i.far,
      i.fab {
        padding-top: 3px;
      }
      span.title {
        flex: 1;
      }
    }
  }

  sb-breadcrumb {
    display: block;
    font-size: 85%;
    line-height: 100%;
    a::after {
      font-size: 8px;
    }
    span.crumb {
      max-width: 30px;
    }
  }
}

#data-room-panel-access {
  padding-bottom: 5px;
}

#data-room-panel-manage {
  float: right;
}

#data-room-panel-nextExpiration {
  font-size: 80%;
  font-style: italic;
  color: @fps-text-secondary;
}

input.asearch-query {
  display: inline-block;
  width: 130px;
  margin-right: 5px !important;
}

#qr-reader {
  width: 400px;
  height: 300px;
  margin: auto;
}

/* -----[ Dashboard Panels: Corporate Actions/Admin Settings ]--------------- */

ul.admin-settings,
ul.corporate-actions {
  margin-left: 0;
  margin-bottom: 0;
  list-style: none;

  li {
    margin-bottom: 5px;

    a > .fa,
    a > .far,
    a > .fab,
    a > .fas {
      margin: 4px 0 0;
      float: left;
    }

    .action-help {
      color: @sb-gray !important;
      .dashboard-panel & {
        margin-left: 3px;
      }
    }

    span {
      margin-left: 25px;
      display: block;
    }
  }

  li:last-child {
    margin-bottom: 0;
  }
}
/* -----[ Dashboard Panel: Stakeholder Actions ]------------------------------ */

.stakeholder-actions {
  i.fa,
  i.fas,
  i.far,
  i.fab {
    color: @sb-green-type;
  }

  ul {
    list-style: none;
  }

  .no-actions {
    color: @fps-text-secondary;
    font-style: italic;
  }

  li {
    color: @sb-green-type;
    font-size: 16px;
    line-height: 1.5em;
  }

  h6 {
    text-transform: uppercase;
    color: @fps-text-secondary;
  }

  .sub-header {
    a {
      color: @sb-green-type;
    }
    border-bottom: 3px dotted @sb-gray;
    h5 {
      margin-bottom: 6px;
      margin-top: 5px;
    }
  }

  .unread {
    font-weight: bold;
  }

  .task {
    background-color: @fps-text;
    padding: 2px;
    color: @sb-white;
    span:first-child {
      margin-left: 5px;
    }
    a {
      color: @sb-white;
    }
  }

  div.content {
    height: auto;
  }
}

.short-list {
  height: 64%;
}

.long-list {
  height: 82%;
}

.longer-list {
  height: 94%;
  h6 {
    margin-top: 4px;
    margin-bottom: 5px;
  }
}

.divider > div {
  border-right: 1px solid @sb-gray-light;
}

#communication-list > div {
  float: left;
  width: 47%;
}

#communication-list > div:last-child {
  border: none;
  width: 53%;
  height: 85%;
  li {
    margin-left: 15px;
  }
}

.flag {
  background-color: @sb-green-type;
  padding: 2px;
  float: left;
  i.fa,
  i.fas,
  i.fab,
  i.far {
    color: @sb-white;
  }
}

.dashboard-panel .divided-panel {
  display: flex;
  align-items: stretch;
  > * {
    flex: 1;
    &:not(:last-child) {
      border-right: 1px solid @sb-gray-light;
      padding-right: 15px;
    }
    &:not(:first-child) {
      padding-left: 15px;
    }
  }
}

.board-stockholder-panel {
  h6 {
    font-weight: bold;
    font-size: 16px;
    margin-bottom: 10px;
  }
  .content {
    .divided-panel {
      padding: 0 15px;
    }
    li:not(last-child) {
      margin-bottom: 10px;
    }
    li li {
      margin-left: 25px;
      margin-bottom: 0;
    }
  }
  .document-date {
    font-weight: normal;
    text-transform: none;
    font-size: 13px;
  }
}

.board-stockholder-missing-sigs {
  margin-left: 15px;
  i.fa,
  i.fas,
  i.far,
  i.fab {
    margin-right: 5px;
  }
  i.fa-exclamation-triangle {
    color: @sb-yellow;
  }
  i.fa-check {
    color: @sb-green;
  }
}

/* -----[ Page : Corporate Actions ]------------------------------ */

div.corporate-actions .header h1 {
  font-weight: 200;
}

div.corporate-actions .actions-side-nav {
  position: fixed;
  padding-top: 25px;
  li {
    padding-bottom: 10px;
    padding-top: 10px;
    border-left: 5px solid @sb-gray-lighter;
    padding-left: 10px;
  }
  li:hover {
    a {
      color: @sb-green-type;
      text-decoration: none;
    }
    border-left: 5px solid @sb-green;
    padding-left: 10px;
  }
}

div.corporate-actions .group-actions .action {
  span.unlocked {
    color: black;
    text-decoration: none;
    &:hover {
      color: @sb-green-type;
      text-decoration: underline;
    }
  }
  span.locked {
    color: @fps-text-secondary;
    text-decoration: none;
  }
}

div.corporate-actions .action-groups {
  position: absolute;
  left: 25%;
  padding-bottom: 50px;
  border-left: dotted @sb-gray-light 4px;
}

div.corporate-actions .row {
  margin-left: 20px;
  margin-right: 0;
}

div.corporate-actions .alert {
  margin-right: 80px;
}

div.corporate-actions .content {
  margin-top: 20px;
  .row {
    display: flex;
  }
}

div.corporate-actions .content i.action-help {
  color: @fps-text-secondary;
}

div.corporate-actions .content i.action-help:hover {
  color: @sb-green-type;
}

div.corporate-actions .content div.group {
  padding-top: 20px;
  padding-bottom: 5px;
}

div.corporate-actions .content div.group i.group-icon {
  font-size: 24px;
  color: @fps-text-secondary;
}

div.corporate-actions .content div.group span.group-name {
  font-size: 20px;
  text-transform: uppercase;
}

div.corporate-actions .content div.group-actions {
  margin-left: 35px;
  padding-top: 5px;
  padding-bottom: 5px;
}

div.corporate-actions .content div.action {
  padding-top: 3px;
  padding-bottom: 3px;
}

/* -----[ Privacy and Terms/Help Pages] ------------------------------------------ */
.help-pages,
.privacy-and-terms {
  padding: 0 15px 15px;

  .backlink {
    margin-top: 25px;
  }

  .nav-tabs {
    margin: 10px -15px;
    padding-right: 0;
    a {
      padding: 0 10px !important;
    }
  }

  .tab-content {
    padding: 0 10px;
  }

  .legal-last-revision {
    font-style: italic;
  }

  h3 {
    margin: 25px 0 -5px;
    color: @fps-text;
  }

  h4 {
    font-weight: bold;
    text-transform: none;
    color: @fps-text;
    margin: 25px 0 10px;
  }

  h3 + p {
    margin-top: 25px;
  }
}

/* -----[ Dashboard Panel: Stakeholders ]----------------------------------- */

.stakeholder-popover {
  h5 {
    font-size: 17px;
    margin: 0 0 11px 0;
  }
}

.stakeholders-panel {
  .header {
    position: relative;
    .loading-spinner {
      color: @fps-text;
      position: absolute;
      top: 3px;
      right: 19px;
      font-size: 14px;
      z-index: @z-index-2;
    }
    input {
      position: absolute;
      top: 1px;
      right: 15px;
      margin-right: 0;
      width: 165px;
      height: 20px;
      padding: 0 19px 1px 3px;
      font-size: 11px;
      font-weight: normal;
      line-height: 20px;
    }
  }
  ul.panel-list {
    margin: 0;
    padding: 0 !important;
    li {
      box-sizing: content-box;
      height: 55px;
      margin-bottom: 0;
      padding: 0 15px 10px 0;
      overflow: hidden;
      .slide-down-animation(
              0.4s, 55px, 0, 10px
      );
      .reveal-animation(0.4s);
    }
    li:last-child {
      padding-bottom: 0;
    }
    li.sb-card-container {
      padding: 10px 15px 10px 10px;
      height: auto;
    }
  }
  .position {
    font-weight: normal;
    color: #90a7a7;
  }
}

.panel-list .item-icon {
  color: @sb-green-type;
}

/* -----[ Dashboard Panel: Funds Panel ]----------------------------------- */
.funds-panel {
  ul {
    list-style-type: none;
  }
}

/* -----[ Dashboard Panel: My Documents ]----------------------------------- */

.board-meeting-panel {
  .stakeholder-actions();

  h5 {
    margin: auto;
    display: inline;
    text-transform: uppercase;
    font-size: 14px;
    color: @fps-text-secondary;
  }

  h6 {
    margin-bottom: 4px;
  }

  .panel-title {
    span {
      font-size: 14px;
    }
  }

  .btn {
    margin-bottom: 3px;
    margin-left: 14px;
  }
}

.my-docs-panel ul li .item-details .item-title {
  margin-bottom: 0;
}

.my-docs-panel ul.panel-list li {
  margin-bottom: 8px;
}

.dashboard-panel .header a:hover {
  display: block;
  background-color: @sb-green-type;
  color: @sb-white;
  text-decoration: none;
}

.dashboard-panel .my-docs-panel .content a:hover,
.dashboard-panel .my-docs-panel .content a:hover .fa,
.dashboard-panel .my-docs-panel .content a:hover .fas,
.dashboard-panel .my-docs-panel .content a:hover .far,
.dashboard-panel .my-docs-panel .content a:hover .fab {
  background-color: @sb-white;
  color: @sb-green-bright;
}

/* -----[ Dashboard Panel: Company Profile ]------------------------------- */

.company-profile .entity-title {
  font-weight: bold;
  font-family: 'Open Sans', Arial, sans-serif;
  font-size: 1.2em;
}

.company-profile .address {
  line-height: 1.2em;
}

#logo-block .logo {
  max-width: 100%;
  width: auto;
  height: auto;
  margin: 0 auto;
}
.context-right > #logo-block {
  padding: 15px;
}

.setup-completeness {
  position: relative;
}

.setup-completeness-space {
  position: relative;
  padding-right: 25px;
}

.setup-completeness .progress span {
  padding-right: 25px;
}

.setup-completeness-help-button {
  position: absolute;
  right: 0px;
  top: -5px;
  font-size: 16px;
}

.company-profile .btn {
  overflow: hidden;
}

.company-profile {
  .entity-details {
    padding-top: 20px;
  }
  .entity-logo {
    display: block;
    float: left;
    max-width: 150px;
    max-height: 150px;
  }
  .entity-logo + .entity-more {
    margin-left: 160px;
  }
}

uib-accordion {
  &.only-one-option {
    .panel-heading {
      display: none;
    }
    .panel,
    .panel-body {
      box-shadow: none;
      padding: 0 !important;
      border: none !important;
    }
  }
  .panel-title {
    text-transform: none;
    > a {
      outline: none;
      text-decoration: none;
    }
  }
  &.disabled .panel-title > a {
    text-decoration: none;
    cursor: default;
  }
}

.fake-accordion-heading {
  color: @fps-text;
  background-color: @panel-default-heading-bg;
  border: 1px solid @nav-tabs-border-color;
  padding: 10px 15px;
  //line-height: 1.42857143;
  border-radius: 1px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
  margin-bottom: 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

#stock_incentive_plans_container .panel-heading .accordion-toggle:before {
  display: inline-block;
  content: '\f0d7';
  font-family: 'Font Awesome 6 Free';
  font-weight: 900;
  text-decoration: none;
  margin: 0px 7px 0px 2px;
}

#stock_incentive_plans_container .panel-heading .accordion-toggle.collapsed:before {
  content: '\f0da';
  font-family: 'Font Awesome 6 Free';
  font-weight: 900;
  margin: 0px 8px 0px 4px;
}

/* -----[ Dashboard Panel: Board Actions ]--------------------------------- */

.board-actions-resolution .resolution-date {
  display: inline-block;
  overflow: hidden;
}

.board-actions-resolution .resolution-title {
  display: inline-block;
  width: 250px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.board-actions-panel {
  .docs {
    height: 60px;
  }
  .alert {
    margin-bottom: 10px;
  }
  .board-member-lists {
    margin-top: 5px;
    padding: 0 15px;
    ul {
      padding-right: 5px;
      margin-bottom: 0;
      list-style-type: none;
      li {
        margin-top: 3px;
        i {
          &::before {
            margin-right: 5px;
            color: @sb-yellow;
          }
          &.fa-check {
            &::before {
              color: @sb-green;
            }
          }
        }
        span {
          font-size: 16px;
        }
      }
    }
  }
}

/* -----[ Dashboard Panel: Company Relationships ]------------------------- */

.relation-box {
  display: block;
  position: relative;
  width: 105px;
  height: 107px;
  overflow: hidden;
  margin: 0;
  border: 3px solid @sb-gray-lighter;

  .logo-wrapper-outer {
    display: table;
    height: 80px;
    width: 100%;
  }

  .logo-wrapper-inner {
    display: table-cell;
    text-align: center;
    vertical-align: middle;

    .logo {
      max-width: 75px;
      max-height: 50px;
      border: none;
    }
  }

  .footer {
    position: absolute;
    padding-top: 3px;
    height: 27px;
    bottom: 0;
    width: 100%;
    background: @fps-box-background;
    text-align: center;
  }

  .workflow-items-box {
    position: absolute;
    top: 2px;
    right: 2px;
    z-index: 1000;
    color: @fps-text-secondary;

    .item-count {
      padding: 0 3px;
      font-weight: bold;
      background: @sb-gray-lighter;
    }
  }

  .diligence-box {
    position: absolute;
    top: 2px;
    left: 2px;
    z-index: 1000;
    color: @fps-text-secondary;
  }
}

.orange-border {
  border: 3px solid @sb-orange;
}

li.relation {
  display: inline-block;
}

.dashboard-panel .content.pending-rel {
  height: 168px;
}
.inactive-relation-box {
  padding: 10px;
  background: @fps-box-background;
  clear: both;

  .footer {
    text-align: right;
  }
}

/* -----[ Relations List Page ]------------------------------------ */

#relationship_table {
  td {
    min-width: 170px;
  }
  .groups {
    min-width: 120px;
    width: 120px;
  }
  .member-name {
    font-weight: bold;
    font-size: 110%;
  }
  button {
    padding-left: 5px;
  }

  a {
    padding-left: 0;
    display: inline;
  }
  .tags {
    text-transform: capitalize;
    display: inline-block;
    width: 178px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    padding-right: 4px;
  }
}

#relations-list {
  .logo {
    max-width: 80px;
    max-height: 50px;
    float: none;
    margin: 0 auto;
  }

  .info {
    font-size: 80%;

    td {
      text-align: center;
    }

    td,
    th {
      padding: 3px;
    }
  }

  .buttons {
    margin-top: 5px;
  }

  .center {
    text-align: center;
  }
}

/* -----[ Stakeholder Management Page ]------------------------------------ */

#stakeholder-role-top {
  border-bottom: 2px dotted @sb-gray-lighter;
}

.stakeholder-role-title {
  font-weight: bold;
}

.stakeholder-role-description {
  margin-left: 30px;
  font-style: italic;
}

.locked-role-item {
  padding-left: 7px;
}

.locked-role-item-lock {
  font-size: 135%;
}

div.role-list-group {
  margin-bottom: 11px;
}

ul.role-list {
  list-style: none;
  padding-left: 20px;
  margin-bottom: 0px;
}

ul.role-list-flat {
  list-style: none;
  padding-left: 0px;
}

li.role-list {
  margin: 10px 0 10px 0;
}

li.role-item .row .label {
  display: none;
}

/* -----[ Workflow Management Page ]--------------------------------------- */

.workflow-card .details {
  font-size: 80%;
}

.workflow-card .remove {
  text-align: right;
}

.workflow-card .actions {
  width: 100%;
}

.workflow-card .status .important {
  color: #b94a48;
  font-weight: bold;
}

.workflow-card .status .info {
  color: #2d6987;
  font-weight: bold;
}

.workflow-card .status span:before {
  font-family: 'Font Awesome 6 Free';
  font-weight: 400;
  content: '\F024';
  padding-right: 1em;
}

#process-history {
  font-size: 80%;
}

#process-history h3 {
  font-size: 120%;
  clear: both;
  padding-top: 10px;
}

#process-history .workflow-info {
  width: 100%;
}

#process-history .col-lg-2 {
  padding: 0 15px;
  width: 20%;
}
#process-history .col-lg-4 {
  padding: 0 0 0 0;
  width: 30%;
}

#process-history .workflow-info > div:first-child {
  font-weight: bold;
  margin-left: 0;
}

#process-history .log {
  list-style: none;
  margin: 0;
  padding: 0;
}

#process-history .log .entry {
  display: block;
  list-style: none;
  clear: both;
}

#process-history .log .entry > div {
  display: inline-block;
  list-style: none;
}

#process-history .log .entry > div:first-child {
  margin: 0;
}

#process-history .row [class*='span'] {
  min-height: 20px;
}

.help-tooltip {
  color: #08c;
  border-bottom: 1px dashed #08c;
}

i.help-tooltip {
  border-bottom: none;
}

/* -----[ Generic filter page classses ] ---------------------------------- */

.fixed {
  position: fixed;
  left: 0;
  right: 0;
  z-index: 100;
}

.batch-size-selector {
  select,
  .form-dropdown {
    border: none;
  }
}

.batch-size-selector .selected {
  color: @fps-text-secondary;
}

/* -----[ Document sign ]------------------------------------------------- */

.big-button .big-button-contents {
  display: inline-block;
  margin-left: 1em;
}

.big-button h4 {
  margin-top: 0;
}

.big-button .fa-huge {
  font-size: 30pt;
}

.big-button .icon {
  width: 3em;
}

.signed-badge {
  color: gold;
  display: relative;
  font-size: 14pt;
  margin-left: -0.8em;
  margin-right: 0.8em;
}

/* -----[ Workflow Management Page ]--------------------------------------- */

#stakeholder-items .stakeholder-item {
  padding: 10px;
  min-height: 65px;
}

#stakeholder-items .stakeholder-item:nth-child(odd) {
  background-color: #f5f5f5;
}

#stakeholder-items .missing {
  font-size: 80%;
}

#stakeholder-items .roles {
  font-size: 80%;
}

#stakeholder-items .details {
  font-size: 80%;
  line-height: 14px;
}

#stakeholder-items .remove {
  text-align: right;
  vertical-align: middle;
  padding-top: 10px;
}

#stakeholder-items .actions {
  width: 100%;
}

/* -----[ Equity Page ]---------------------------------------------------- */

.equity-grant-wrap {
  position: relative;
}

#equity-chart-toggle {
  position: absolute;
  top: 10px;
  right: 15px;
  z-index: 1000;
}

#equity-grants {
  .chart {
    height: 300px;
    padding-top: 20px;
  }
  .inactive {
    text-decoration: line-through;
  }
}

/* -----[ Stock Ledger ]--------------------------------------------------- */

.stock-ledger {
  margin: @standard-margin;
  .inactive {
    text-decoration: line-through;
  }
  > table {
    margin: 0;
  }
  > p {
    margin-top: 15px;
  }
}

/* -----[ HR Onboarding Page ] -------------------------------------------- */

.hr-onboarding-table {
  white-space: normal;
  word-wrap: normal;
  margin: auto;
  text-align: left;
}

/* -----[ Root Pages ]----------------------------------------------------- */

/* Navigation lists (backported from bootstrap 2) */

.nav-header {
  display: block;
  padding: 3px 15px;
  font-size: 11px;
  font-weight: bold;
  line-height: 20px;
  color: #999999;
  text-shadow: 0 1px 0 rgba(255, 255, 255, 0.5);
  text-transform: uppercase;
}

.nav li + .nav-header {
  margin-top: 9px;
}

.nav-list {
  padding-right: 15px;
  padding-left: 15px;
  margin-bottom: 0;

  > li {
    &:not(:last-child) {
      margin-bottom: 8px;
    }
  }

  > li > a,
  .nav-header {
    margin-right: -15px;
    margin-left: -15px;
    color: @fps-text;
  }

  > li > a {
    font-size: 14px;
    padding: 0 0 0 15px;
    text-decoration: none;
  }

  > .active > a,
  > .active > a:focus,
  > .selected > a,
  > .active > a:focus {
    color: @sb-green-type;
    border-left: 5px solid @sb-green;
    padding-left: 10px;
  }

  > li > a:hover {
    color: @sb-green-bright;
    background-color: inherit;
    text-decoration: underline;
  }

  [class^='fa-'],
  [class*=' fa-'] {
    margin-right: 2px;
  }

  .divider {
    *width: 100%;
    height: 1px;
    margin: 9px 1px;
    *margin: -5px 0 5px;
    overflow: hidden;
    background-color: @sb-gray-light;
    border-bottom: 1px solid @sb-white;
  }
}

.picture-block-image {
  display: block;
  margin: 0 auto 10px;
  text-transform: uppercase;
  padding: 10px 0;
  font-size: 0.7em;
  color: @fps-text-secondary;

  .fa,
  .fas,
  .far,
  .fab {
    font-size: 8em;
  }
}

.indent {
  margin-left: 2em;
}

/*---------------[Resolutions UI]------------------------ */
#res-list {
  .col-lg-9 .btn-int,
  .col-lg-7 .btn-int {
    float: right;
  }

  .col-lg-3 {
    padding-top: 5px;
    label {
      padding: 5px 0 0;
    }
    .checkradios-checkbox {
      margin-left: 0;
      margin-right: 5px;
    }
  }

  .col-lg-1 {
    padding-top: 9px;
  }

  > div {
    padding: 5px 0;
    border-top: 1px solid @sb-gray-light;
  }

  > div:last-child {
    border-bottom: 1px solid @sb-gray-light;
  }

  h4 {
    text-transform: none;
  }
}

.add-team-member {
  .subform .form-group {
    border: none;
    margin-left: 0;
    margin-right: 0;
    .col-md-4 {
      padding-left: 60px;
    }
  }
  .new-user-form {
    margin-bottom: 10px;
  }
}

#add-board-resolution {
  margin-top: 10px;
}

#approve_all {
  margin: -2px 0 7px;
}

.resolutions-heading {
  padding-top: 25px;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: normal;
  color: @sb-gray-darker;
}

/*-----------------[Attendee UI]-------------------------------*/

#other-attendees-modal {
  .modal-body .row {
    margin-top: 15px;
  }

  .modal-body .row:first-child {
    margin-top: 0;
  }
}

button.add-other-attendee-btn {
  margin-top: 10px;
}

/*---------------[Workflow Admin UI]---------------------------*/

.settings-groups {
  padding-top: 20px;
}

.settings-group {
  padding-bottom: 30px;
  padding-left: 40px;
}

ul.action-list {
  width: 500px;
  padding-top: 10px;
  padding-left: 0;
  margin-left: 0;
  margin-bottom: 0;
}

ul.action-list li {
  list-style: none;
  line-height: 35px;
  padding: 0px 10px;
}

ul.action-list li:nth-child(odd) {
  background: #eff2f2;
}

/*---------------[Workflow Admin Settings]---------------------------*/

.workflow-admin .backlink {
  margin-top: 23px;
  padding-right: 20px;
}

.workflow-admin .header {
  margin-left: 35px;
}

#workflow-settings {
  padding: 10px 10px 0px 10px;
}

.default-nav {
  position: relative;
}

.default-nav .context-bar {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 15px;

  .default-sets > li {
    position: relative;
    word-wrap: break-word;
    padding-right: 52px;
    button {
      position: absolute;
      top: 2px;
      right: 2px;
    }
    > a {
      margin-left: -10px;
    }
  }
}

.default-nav .context-bar .nav-list {
  padding-top: 10px;
}

.default-nav .default-sets {
  border-bottom: 1px solid @sb-gray-light;
  padding-left: 10px;
  padding-bottom: 10px;
  .fa,
  .fas,
  .far,
  .fab {
    color: @sb-green-type;
    margin-right: 4px;
  }
  .btn-link {
    outline: none;
  }
  .edit-key {
    margin-right: 14px;
  }
  .btn-link {
    padding-top: 5px;
  }
}

.context-bar {
  position: fixed;
  width: @sidebar-width;
  border-right: 3px dotted @sb-gray-light;
}

.default-body {
  padding-left: 0px;
  margin-left: 0px;
  border-left: 0px;
  font-weight: bold;
}
.default-field {
  color: @fps-text-secondary;
}

.hr-admin .backlink,
.workflow-settings .backlink {
  margin-top: 23px;
  padding-right: 20px;
}

.workflow-settings .teamlink {
  clear: both;
  margin-top: 5px;
  padding-right: 20px;
}

.hr-admin .header,
.workflow-settings .header {
  margin-left: 35px;
}

.workflow-settings,
.hr-admin {
  .form-actions:after {
    content: ' ';
    clear: both;
    display: block;
  }
  .form-actions .btn {
    float: right;
  }
}

.workflow-description-box {
  background-color: @fps-box-background;
}

.workflow-description-box .workflow-title {
  padding: 30px 20px 5px 20px;
  font-weight: 600;
  color: @sb-gray-darker;
}

.workflow-description-box .workflow-description {
  padding: 5px 20px 30px 20px;
}

.workflow-settings bordered {
  border-top-style: solid;
  border-color: @sb-gray-lighter;
  border-width: 2px;
}

.checkbox-padding {
  padding-top: 13px;
}
.subfield {
  clear: both;
}

.workflow-settings .readonly {
  .widget {
    display: inline;
    margin-right: 2px;
  }
  margin-top: 5px;
}

/*---------------[DocTemplate Admin UI]---------------------------*/
.doctemplates ul.doctemplate-list {
  width: 500px;
  padding-top: 10px;
  padding-left: 0;
  margin-left: 35px;
  margin-bottom: 0;
  margin-top: 20px;
}
.doctemplates ul.doctemplate-list li {
  list-style: none;
  line-height: 35px;
  padding: 0px 10px;
}
.doctemplates ul.doctemplate-list li:nth-child(odd) {
  background: @fps-list-odd;
}
.doctemplates li.subheader h4 {
  line-height: inherit;
  margin-bottom: 0;
}
.doctemplates span.revision {
  float: right;
}
.doctemplate-admin .backlink {
  margin-top: 23px;
  padding-right: 20px;
}
.doctemplate-admin .header {
  margin-left: 35px;
}

/*---------------[DocTemplate Viewer UI]---------------------------*/
.dt-viewer-header {
  margin: 0 25px 0 25px;
}

.dt-viewer-content {
  margin: 0 10px;
}

.dt-viewer-header {
  h1.dt-document-name {
    font-weight: 400;
    text-transform: none;
    font-size: 200%;
    margin: 0 100px 0 0;
    color: @sb-gray-darker;
  }
  .btn-print {
    margin-top: 7px;
    float: right;
  }
  .backlink {
    margin-top: 23px;
  }
}
.dt-viewer-header-details {
  padding: 0 15px 10px;
  margin: 5px 0 0;
  border-bottom: 3px solid @sb-gray-light;

  span {
    color: @fps-text;
    margin: 0px;
    text-transform: none;
  }
}
.dt-viewer-header-details-txt {
  color: @fps-text-secondary;
  margin: 0px;
  margin-right: 10px;
  text-transform: uppercase;
  font-size: 14px;
}
.dt-viewer-content-left {
  border-right: dotted @sb-gray-light 4px;
  padding-right: 11px;
}
.dt-viewer-content .col-md-3 {
  padding-right: 20px;
  h3 {
    color: @fps-text;
  }
}

/*---------------[Exercise Details UI]------------------------ */
.excercise-details {
  margin-right: 15px;
  margin-left: 15px;

  .document-card {
    border: 10px solid @sb-gray;
    padding: 10px;

    dl {
      width: auto;
    }
  }
  .document-card:last-child {
    border-top: none;
  }
}

/*---------------[Workflow Process Settings Overview]-----------------------*/

.settings-diff {
  h3 {
    margin-left: 17px;
  }
}

.settings-overview {
  padding-top: 20px;
  margin: 0 17px 20px;
  h4 {
    display: inline;
  }
  .btn {
    float: right;
    position: relative;
  }
}

.settings-overview .setting-title {
  font-weight: 600;
  padding-bottom: 10px;
  text-transform: uppercase;
}

.settings-overview .setting-description {
  padding-bottom: 5px;
}

.settings-overview .section {
  color: @sb-blue;
}

.settings-overview .sub-section {
  color: @sb-gray-darker;
  margin-left: 20px;
  font-style: italic;
}

.settings-overview .group-border {
  border: solid 2px @sb-gray-light;
  margin-right: 0;
  margin-left: 0;
  display: flex;
}

.settings-overview .group-border-top {
  .settings-overview .group-border();
  border-bottom: none;
}

.settings-overview .group-border-bottom {
  .settings-overview .group-border();
  border-top: dotted 3px @sb-gray-light;
}

.settings-overview {
  .process-actions {
    padding-left: 10px;
  }
  .process-action {
    padding-bottom: 5px;
    i.fa,
    i.fas,
    i.far,
    i.fab {
      color: @fps-text-secondary;
    }
  }
  .dividers .col-md-4:not(:last-child) {
    border-right: 3px dotted @sb-gray-light;
  }
  .col-md-4 {
    padding-top: 20px;
    align-items: stretch;
  }
  .col-md-4:not(:last-child) {
    padding-right: 9px;
  }
  ul {
    list-style: none;
    margin-left: -29px;
    margin-top: 10px;
  }
  li {
    margin-left: -15px;
  }
  li:not(:first-child) {
    margin-left: 9px;
  }
  .continued h4 {
    margin-left: 22px;
  }
}

.settings-group-heading {
  padding-left: 15px;
}

/*---------------[Counsel Info Snippet]------------------------------------ */

.counsel-info-snippet {
  border: 1px solid @sb-gray-light;
  padding: 7px;
  margin: 0 0 10px 0;

  .col-md-5 {
    border-right: 1px solid @sb-gray-light;
    padding-right: 0;
    padding-left: 8px;
  }

  .logo-wrapper-outer {
    display: table;
    height: 60px;
    width: 100%;
  }

  .logo-wrapper-inner {
    display: table-cell;
    text-align: center;
    vertical-align: middle;
  }

  .logo {
    max-width: 100px;
    max-height: 60px;
  }

  .primary-counsel {
    .lead-in {
      text-transform: uppercase;
      color: @fps-text-secondary;
      font-size: 13px;
      font-weight: 600;
      margin-bottom: 5px;
    }

    .counsel-avatar {
      float: left;
      margin-right: 5px;
    }

    .counsel-name {
      font-weight: bold;
      font-size: 16px;
      line-height: 1.1em;
    }

    .counsel-email {
      line-height: 1.1em;
    }
  }
}

/*---------------[Counsel Admin]------------------------------------------- */

#registered-counsel {
  margin-left: 20px;
  .no-counsel-text {
    padding-bottom: 10px;
  }

  .logo {
    display: block;
    max-width: 150px;
  }

  .address-box {
    float: left;
    padding-right: 40px;
  }
}

/*---------------[Counsel involvement for Process Settings]---------------- */

.counsel-involvement {
  margin-left: 20px;
}

.counsel-involvement .heading-title {
  margin-left: 17px;
  a {
    display: inline;
    float: right;
    margin-right: 17px;
  }
  h3 {
    display: inline;
  }
}

.counsel-involvement .heading-description {
  margin-left: 17px;
}

/*---------------[Wait for Signatures/Approvals UI]------------------------ */
.approve-sig-content {
  margin: 0 auto;
  width: 90%;
}

.approve-content .alert,
.approve-sig-content .alert {
  margin-bottom: 11px;
}

.approve-document-group {
  .approve-header {
    display: block;
    background: @sb-gray-light;
    padding: 5px 10px;
    color: @sb-white;
    font-weight: bold;
  }

  h3 {
    color: @fps-text;
    font-size: 16px;
    margin: 0;
    padding-left: 20px;
  }

  h4 {
    font-size: 19px;
    font-family: 'Source Sans Pro', Helvetica, Arial, sans-serif;
    text-transform: none;
    margin-top: 0;
  }

  h4:before {
    font-family: 'Font Awesome 6 Free';
    font-weight: 900;
    text-transform: none;
    margin-right: 8px;
  }

  .row .row {
    margin-left: 0;
    margin-right: 0;
  }

  .approve-col-offset-1 {
    margin-left: 2%;
  }

  .approve-sig-label {
    text-transform: uppercase;
    font-size: 12px;
    color: @fps-text-secondary;
    padding-top: 3px;
  }

  .signatures {
    padding-left: 20px;
  }
  strong {
    font-family: 'Source Sans Pro', Helvetica, Arial, sans-serif;
    font-weight: bold;
  }

  strong:before {
    font-family: 'Font Awesome 6 Free';
    font-weight: 900;
    margin-right: 5px;
  }

  strong.fa-exclamation-triangle:before {
    color: @sb-yellow;
  }

  strong.fa-check:before {
    color: @sb-green;
  }
}

.approve-document {
  border-bottom: 1px solid @sb-gray-light;
  padding-bottom: 11px;
  padding-top: 10px;
}

.approve-document:last-child {
  border: none;
}

.approve-time {
  color: @fps-text-secondary;
  font-size: 15px;
}

.approve-document-actions {
  float: right;

  .btn {
    margin: 10px 0 0 3px;
    float: right;
  }
}

.approve-pending-notice {
  margin: 0 0 0;
  font-size: 20px;
  font-weight: bold;
  text-align: right;
  text-transform: uppercase;
}

/*--------------------[Define BOD/Founder/Officer]------------------------ */

.board-size-row {
  .form-group {
    border: none;
    margin: 0;
  }

  input {
    width: 35%;
  }

  > div {
    padding: 0;
  }
}

.form-row h5 {
  color: @fps-text;
  font-weight: 600;
  margin: 0;
}

.form-row {
  border-top: 1px solid @sb-gray-light;
  padding: 15px 0;
}

.officer-row {
  margin-bottom: 15px;
}

.officer-row:nth-child(2) {
  margin-top: 15px;
}

.officer-lbl {
  padding: 0 0 0 15% !important;
}

#founders li,
.officer-row .btn-link,
#bod li {
  .fa,
  .far,
  .fab,
  .fas {
    font-size: 16px;
  }
}

.officer-lbl:before {
  content: '\21b3';
  color: @fps-text-secondary;
}

.officer-initials {
  margin-right: 5px;
}

.officer-initials img {
  height: 25px;
  width: 25px;
}

.officer-initials span {
  font-weight: 300;
  text-transform: uppercase;
  background-color: @sb-gray-light;
  display: inline-block;
  padding-top: 1px;
  width: 25px;
  height: 24px;
  border-radius: 25px;
  text-align: center;
  color: @sb-gray-darker;
}

// Bootstrap btn styling conflicts with FontAwesome 5
// Force proper FontAwesome styling
.btn,
.btn-link {
  &.fa,
  &.fas {
    font-weight: 900;
  }
  &.far,
  &.fab {
    font-weight: 400;
  }
}

.btn-link {
  padding: 0;
}

.edit-person {
  margin-left: 5px;
}

.user-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.user-list li {
  margin-bottom: 6px;
}

.user-list li:last-child {
  margin-bottom: 10px;
}

/*--------------------[Founder Stock Distribution]------------------------ */

#vesting-switch label {
  width: 75%;
  font-weight: normal;
}
#vesting-switch .control-div {
  width: 25%;
}

#fsd .vesting label {
  margin-right: 1.5em;
  font-weight: normal;
}
#fsd .vesting input[type='radio'] {
  margin-right: 0.5em;
}

#fsd table thead tr th:nth-child(1) {
  width: 20%;
}

#fsd table thead tr th:nth-child(2) {
  width: 13%;
}

#fsd table thead tr th:nth-child(3) {
  width: 11%;
}

#fsd table thead tr th:nth-child(4) {
  width: 28%;
}

#fsd table thead tr th:nth-child(5) {
  width: 13%;
}

#fsd table thead tr th:nth-child(6) {
  width: 15%;
}

/* ----------------------[grant details]--------------------------- */
.exercisable-shares-box {
  text-align: center;
}

.vesting-schedule {
  height: 300px;
  overflow-y: scroll;
}

/* ----------------------[ad hoc]---------------------------------- */

#postinc-nodocs-choice h4 {
  display: inline;
}

.dr-exhibits {
  margin-top: 2ex;
}

.metadata-changelog-modal {
  z-index: 1045;
}

.wide-modal .modal-dialog,
.metadata-changelog-modal .modal-dialog {
  width: 970px;
}

.h3margin {
  display: block;
  margin-top: 20px;
  margin-bottom: 10px;
  margin-color: #eee;
}

.reps-and-warranties h4 {
  margin: 0 2em;
}

.reps-and-warranties textarea {
  margin-bottom: 1ex;
}

.fileinput {
  width: 100%;
}

.fileinput,
.fileinput .btn,
.fileinput input,
.fileinput .form-control {
  cursor: pointer !important;
}

.fileinput input {
  z-index: -1;
}

/* Unfix height of .form-control for jasny file uploads: file uploads shows
 * image previews and has to be resized according to previewed image height.
 */
.fileinput .form-control {
  height: auto;
  min-height: 32px;
}

/* Do not let preview image to take more width that we have on screen. */
.fileinput-preview img {
  max-width: 90%;
}

.record-list-modal {
  z-index: 2000;
}

.dropdown-menu {
  /*
   * This height fits on the 768 screens. It fits 10 items without
   * scrolling.  If this seems restrictive on big screens, we can
   * customize the cutoffs with @media directives.
   */
  max-height: 620px;
  overflow-y: auto;
}

.upload-existing-doc-picker {
  label {
    padding-top: 0;
    margin-bottom: 0;
    margin-left: @subtree-padding;
  }

  ul {
    list-style-type: none;
    padding-left: @subtree-padding;
  }

  li span {
    cursor: pointer;
  }
}

.upload-form-space {
  padding: 0 3em;
}

.some-space {
  padding: 2ex 3em;
}

.some-padding-top {
  padding-top: 10px;
}

.inputlike {
  border: 1px solid #e5e5e5;
  border-radius: 4px;
  padding: 3px;
}

.legal-msg {
  h5 {
    margin: 0 0 5px;
  }
  span {
    display: block;
    position: relative;
    margin: -22px 0 5px 22px;
  }
  div.col-md-12:last-child span {
    margin-bottom: 0;
  }
  .header {
    margin-top: 25px;
    margin-bottom: 10px;
    text-transform: uppercase;
    font-size: 16px;
    font-weight: bold;
    color: @fps-text;
    display: block;
  }
}

.legal-msg-bottom {
  padding-top: 15px;
}

/**************************************************************************/
/* New look hacks */

.popover-title {
  text-transform: none;
  font-weight: bold;
}

.popover {
  .description {
    font-size: small;
  }
}
.popover-fixed {
  position: fixed;
}

#title-bar {
  height: 45px;
  background-color: #fff;
  padding-left: 15px;
  padding-top: 13px;
  margin-top: 0;
  margin-bottom: 0;
  color: @sb-gray-darker;
}

.emphasis-title {
  font-family: 'Source Sans Pro', Helvetica, Arial, sans-serif;
  font-weight: 300;
  font-size: 30px;

  &.emphasis-title-smaller {
    font-size: 26px;
  }
}

.subemphasis-title {
  font-family: 'Source Sans Pro', Helvetica, Arial, sans-serif;
  font-weight: 300;
  font-size: 24px;
  color: @fps-text-secondary;
}

.superemphasis-title {
  font-family: 'Source Sans Pro', Helvetica, Arial, sans-serif;
  font-weight: 300;
  font-size: 36px;
  color: @fps-section-header;
}

.form-actions form {
  margin: 0;
  padding: 0;
}

.alert-extra-padding {
  padding: 10px 25px 10px 10px;
  line-height: 22px;
}
.alert-popup-msg {
  display: none;
  margin-left: 25px;
}

.indent-form {
  margin-left: 20px;
}

.value-edit-trigger {
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  color: @sb-green-type;
}

.full-width-button {
  width: 100%;
  display: block;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  font-size: 19px !important;
  margin-left: auto;
  margin-right: auto;
}
.form-actions .btn {
  height: 25px;
  text-transform: uppercase;
  line-height: 90%;
  margin: 0 5px;
}
.form-actions .btn-primary {
  font-weight: bold;
}

.viewspace {
  padding: 0px 10px 20px 10px;
}

.collapse-caret:before {
  content: '\f0da';
  text-decoration: none;
  margin: 0 7px 0 0;
}
.collapsed .collapse-caret:before {
  content: '\f0da';
  margin: 0 8px 0 3px;
}

.panel-open .collapse-caret:before {
  content: '\f0d7';
  text-decoration: none;
  margin: 0 7px 0 0;
}

.navbar .caret {
  color: #d07f00;
}

.navbar {
  .avatar-image {
    width: 30px;
    height: 30px;
    .grayscale();
  }
  .avatar-initials {
    width: 30px;
    height: 30px;
    padding: 3px 0 0 0;
    background-color: @fps-text;
  }
}

.avatar-initials {
  background-color: @sb-gray-light;
  text-align: center;
  vertical-align: middle;
  display: inline-block;
  padding: 3px;
  text-transform: uppercase;
  font-size: 16px;
}

.avatar.small {
  .avatar-image {
    width: 25px;
    height: 25px;
  }
  .avatar-initials {
    width: 25px;
    height: 25px;
  }
}

.avatar-medium {
  width: 30px;
  height: 30px;
}
.avatar.medium {
  .avatar-image {
    width: 30px;
    height: 30px;
  }
  .avatar-initials {
    width: 30px;
    height: 30px;
    padding-top: 6px;
  }
}

.avatar.large {
  .avatar-image {
    width: 50px;
    height: 50px;
  }
  .avatar-initials {
    width: 50px;
    height: 50px;
    font-size: 25px;
  }
}

#wrap > .container {
  min-height: 100%;
  position: relative;
}

.missing {
  margin: 0 25px;
}

#content-background {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -100;
  .container {
    height: 100%;
    .inner {
      background-color: white;
      height: 100%;
    }
  }
}

#content {
  background-color: white;
}

#sig-block .sig-image {
  max-width: 100%;
  max-height: 80px;
  margin-bottom: 10px;
  border-bottom: 1px solid @sb-gray;
}

.form-separator {
  border-top: 12px solid @sb-gray-lighter;
  text-align: center;
  margin: 30px 0;
}

.hide-subform-label {
  > .checkradios-checkbox,
  > .checkradios-radio {
    margin-right: 8px;
  }
  > span {
    position: relative;
    top: 1px;
  }
}

.angular-hideable-subform .angular-widget label {
  padding-left: 25px;
  &:before {
    content: '\21b3';
    padding-right: 5px;
  }
}

.form-separator label {
  background-color: white;
  padding: 0 5px;
  margin: 0 auto;
  color: #8dc641;
  text-transform: uppercase;
  font-size: 15pt;
  font-weight: normal;
  /*display: block;*/
  top: -20px;
  position: relative;
}

// -------------------[Shoobx Font]------------------------------
@font-face {
  font-family: 'Shoobx';
  font-style: normal;
  font-weight: normal;
  src: url(./../fonts/shoobx.woff);
}

.sbf {
  display: inline-block;
  font-style: normal;
  font-weight: normal;
  font-family: Shoobx;
  position: relative;
  cursor: default;
}

.sbf-x:before {
  content: 'x';
}

.sbf-shoobx-generated:before {
  content: 'a';
}

.sbf-metadata-complete:before {
  content: 'b';
}

.sbf-fully-signed:before {
  content: 'c';
}

.sbf-document-missing:before {
  content: '!';
  color: @sb-blue;
}

.sbf-document-pending:before {
  content: 'f';
  color: @sb-blue;
}

.sbf-unmanaged {
  color: @sb-red;
}

.sbf-crossed:after {
  content: 'd';
  color: @fps-text;
  position: absolute;
  left: 0;
  top: 0;
}

@font-face {
  font-family: 'Shoobx2';
  font-style: normal;
  font-weight: normal;
  src: url(./../fonts/shoobx.ttf);
}

// -------------------[/Shoobx Font]------------------------------

@font-face {
  font-family: 'Garamond';
  font-style: normal;
  font-weight: normal;
  src: local('Garamond-Regulargular'), url(./../fonts/AGaramondPro-Regular.ttf);
}

@font-face {
  font-family: 'Garamond';
  font-style: italic;
  font-weight: normal;
  src: local('Garamond-Italic'), url(./../fonts/AGaramondPro-Italic.ttf);
}

@font-face {
  font-family: 'Garamond';
  font-style: normal;
  font-weight: bold;
  src: local('Garamond-Bold'), url('./../fonts/AGaramondPro-Bold.ttf');
}

@font-face {
  font-family: 'Garamond';
  font-style: italic;
  font-weight: bold;
  src: local('Garamond-BoldItalic'), url('./../fonts/AGaramondPro-BoldItalic.ttf');
}

.rml-content {
  font-family: Garamond, serif;
  margin: 3em 4ex;
  color: #000;
  h1 {
    clear: both;
  }
}

.light-margin {
  margin: 3ex 4ex;
}

//------------------[Terms WI Outline]-------------------------
#section-outline {
  > ul {
    padding-left: 0;

    > li {
      position: relative;
      list-style: none;
      border: 2px solid @sb-gray-lighter;
      padding: 1ex 1em 1ex 2em;

      .fa,
      .fas,
      .far,
      .fab {
        color: @fps-text-secondary;
        font-size: 120%;
        position: absolute;
        top: 0px;
        left: 7px;
        cursor: pointer;
        padding: 10px;
      }
      .fa:before,
      .fas:before,
      .far:before,
      .fab:before {
        content: '\f0da'; // fa-caret-right
      }

      .outline-title {
      }

      .terms {
        font-size: 12px;
        color: @fps-text-secondary;
        font-weight: 600;
      }

      .help {
        display: none;
      }
    }
    > li.opened {
      .fa:before,
      .fas:before,
      .far:before,
      .fab:before {
        content: '\f0d7'; // fa-caret-down
      }
      .fa,
      .fas,
      .far,
      .fab {
        left: 4px;
      }

      .help {
        display: block;
      }
    }
    > li:not(:last-child) {
      border-bottom: none;
    }
  }
}

@standard-padding: 15px;
@sidebar-width: 210px;

.workitem-macro {
  .sidebar {
    // For scroll:
    position: relative;
    height: 100%;
    overflow: hidden;

    width: @sidebar-width;
    padding-left: 0;
    background-color: @sb-white;
    border-right: 3px dotted @sb-gray-light;
    padding-right: @standard-padding;

    pointer-events: auto;
  }

  > .content {
    padding-top: 10px;
    padding-left: @standard-padding;
    padding-right: @standard-padding;
    padding-bottom: @standard-padding;
    background-color: @sb-white;
  }

  .sidebar-outer + .content {
    margin-left: @sidebar-width;
  }
}

.large-search-bg {
  background: url('./../img/search.png') top left no-repeat;
  min-height: 120px;
  min-width: 120px;
}
.system-error {
  margin-left: 100px;
  padding: 90px 0 0 110px;
  background: url('./../img/boxman_error.png') 0% 88px no-repeat;

  .error-code {
    font-weight: normal;
    color: @fps-text-secondary;
    font-size: 12px;
  }

  .actions {
    margin-top: 30px;
  }
}

#newusers .empty-text {
  font-style: italic;
}

/* -----[ Workitem: Exercise Options ]------------------------------------ */

#form-widgets-shares_selector_custom-row .label {
  color: @fps-text;
  background: transparent;
  font-size: 16px;
  margin: 0;
  padding: 0 0 5px 14px;
  display: block;
  text-align: left;
}

#form-widgets-payment_type_other_value-row .label {
  color: @fps-text;
  background: transparent;
  font-size: 16px;
  margin: 0;
  padding: 0 0 5px 14px;
  display: block;
  text-align: left;
}

/* -----[ Workitem: select_document_delegation_options ]----------------- */
#select-document-delegation-tags {
  clear: both;

  ul {
    list-style-type: none;
  }
  .checkradios-checkbox {
    margin: 0 3px 2px 0;
    top: -4px;
  }
}

/*-----[ Attendance Widget ---*/
div.attendance-widget {
  .name-label {
    width: 300px;
    overflow: ellipse;

    .checkradios-checkbox {
      margin-left: 0px;
    }
  }
  .exec-sesh-label {
    margin-left: 10px;
  }
}

div.sub-choice {
  display: inline-block;
  padding-left: 20px;
  font-size: 15px;
  font-weight: 100;
  label {
    font-weight: 500;
  }
}

/*-----------[Jcrop]------*/
.jcrop-holder {
  margin: 0 auto;
}

.control-subprocesses-workitem {
  .subject {
    margin-bottom: 25px;
  }
}

.control-subprocesses-workitem,
.add-and-manage-subprocesses-workitem {
  .active-process {
    text-transform: none;
  }
  .overlay {
    top: 50%;
    left: 50%;
    width: 500px;
    margin-left: -250px;
    z-index: 1001;
    position: fixed;
    .spinner {
      text-align: center;
      font-size: 48px;
    }
  }
  .process-list {
    padding-left: 0;
    li {
      list-style: none;
    }
  }
}

.onboarding-dla-banner {
  @dla-green: rgb(63, 156, 53);
  background: @sb-green;
  background: -webkit-linear-gradient(left, @sb-green 0%, @dla-green 100%);
  background: linear-gradient(to right, @sb-green 0%, @dla-green 100%);
  padding: 50px 130px;
  color: @sb-gray-darker;
  margin-top: -57px;
  margin-bottom: 20px;
  h2 {
    text-transform: none;
    color: #fff;
    font-size: 27px;
    margin-top: 0;
  }
}

.onboarding-westaway-banner {
  @westaway-brown: rgb(200, 134, 102);
  background: white;
  background: -webkit-linear-gradient(left, white 0%, @westaway-brown 100%);
  background: linear-gradient(to right, white 0%, @westaway-brown 100%);
  padding: 50px 130px;
  color: @sb-gray-darker;
  margin-top: -57px;
  margin-bottom: 20px;
  h2 {
    text-transform: none;
    color: @westaway-brown;
    font-size: 27px;
    margin-top: 0;
  }
}

.onboarding {
  .padding-intro {
    padding-left: 100px;
    padding-right: 100px;
  }

  .padding-disclaimers {
    padding-left: 200px;
    padding-right: 200px;
  }
  .main-text {
    padding: 0px 5px 20px 5px;
  }
  h2 {
    font-size: 30px;
    font-weight: normal;
    display: block;
    color: @sb-green;
    text-transform: none;
  }
  h2.extra-padding {
    padding-left: 100px;
  }
  h3 {
    font-size: 35px;
    display: block;
    color: #7ab031;
    text-transform: none;
  }
  h4.padded-top {
    padding-top: 20px;
  }
  h4 {
    text-transform: none;
    font-size: 22px;
    font-weight: 300;
    padding-bottom: 5px;
  }
  .dotted-border {
    border-left: 3px dotted @sb-gray-light;
    padding-left: 12px;
  }
  .centering {
    text-align: center;
  }
  .bold-emphasis {
    color: #7ab031;
    font-style: italic;
    font-weight: bold;
  }
  .negative-bold-emphasis {
    color: #c42126;
    font-style: italic;
    font-weight: bold;
  }
  .emphasis {
    color: #7ab031;
    font-style: bold;
  }
  .gray-box {
    background-color: @sb-gray-steps;
    padding: 10px 20px;
  }
}

.marketing-list {
  padding: 0;
  list-style: none;
}

.marketing-item {
  float: right;
  padding: 15px;
  background: @fps-box-background;
  display: inline-block;
  margin-top: 10px;
}

.email-verification {
  padding: 50px 20%;

  .token-field {
    width: 100%;
    font-size: 16pt;
    font-weight: bold;
    padding: 2pt 6pt;
  }

  .token-hint {
    padding-top: 2pt;
    color: @fps-text-secondary;
    font-size: 82%;
  }
}

.filter-categories #form-widgets-search {
  width: 70%;
  display: inline-block;
  margin: 3px;
}

body.no-selection {
  -o-user-select: none;
  -ms-user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  cursor: default; // so user doesn't get the selection cursor
}
*[sb-scrollable] {
  @fake-scrollbar-width: 8px;
  @fake-scrollbar-offset: 2px;
  overflow: hidden !important;
  position: relative;
  > *:first-child {
    overflow-y: scroll;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
  }
  &[horizontal] > *:first-child {
    overflow-x: scroll;
  }
  > .scrollbar {
    position: absolute;
    background: @sb-gray-light;
    border-radius: @fake-scrollbar-width;
    z-index: 2;
    opacity: 0.6;
    transition: opacity 0.2s ease-in-out;
    display: none;
    &.scrollbar-y {
      width: @fake-scrollbar-width;
      right: @fake-scrollbar-offset;
    }
    &.scrollbar-x {
      height: @fake-scrollbar-width;
      bottom: @fake-scrollbar-offset;
    }
    &.visible {
      display: block;
    }
    &:hover {
      opacity: 0.85;
    }
  }
}

.load-more-loader {
  position: fixed;
  z-index: 1001;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  font-size: 36px;
}

.info-message-checkpoint {
  font-size: 45px;
  float: left;
  margin: 0 20px 10px 0;
}

.mb-15 {
  margin-bottom: 15px;
}

.nowrap {
  white-space: nowrap;
}

/* -----[ Wait step during legal review ]----------------- */
/* Remove list bullets and add space between text and download icon */
ul.list-docs-wait-step {
  list-style: none;
  i {
    padding-right: 10px;
  }
  .sb-gray {
    color: @fps-text-secondary;
  }
}

/* -----[ Peer company warning in asc718]----------------- */
.peer-warning {
  margin-left: 50px;
  margin-bottom: 10px;
}

/* -----[ Unsupported browsers banner ]----------------- */
.unsupported-browser-alert {
  margin: 15px;
}

@import 'components/document-template-reviewer.less';
@import 'components/exercise-options-calc.less';
@import 'components/document-download-button.less';
@import 'components/sb-pdf-upload.less';
@import 'forms.less';
@import 'field-configuration.less';
@import 'approval.less';
@import 'dashboard.less';
@import 'role.less';
@import 'breadcrumb.less';
@import 'radio-drop-down.less';
@import 'process.less';
@import 'stakeholder.less';
@import 'stakeholder-modal.less';
@import 'navbar.less';
@import 'entity-profile.less';
@import 'base-list-page.less';
@import 'data-room.less';
@import 'lists.less';
@import 'profile.less';
@import 'hr.less';
@import 'board.less';
@import 'expiration-date.less';
@import 'panels.less';
@import 'relationships.less';
@import 'workitem/review-and-edit-documents.less';
@import 'workitem/add-and-manage-subprocesses.less';
@import 'workitem/complete-profile.less';
@import 'workitem/sign-documents.less';
@import 'workitem/bod.less';
@import 'workitem/carta-integration.less';
@import 'workitem/resolution.less';
@import 'workitem/counsel-settings.less';
@import 'workitem/counsel-settings-summary.less';
@import 'workitem/fill-acroform.less';
@import 'workitem/frb-intro.less';
@import 'workitem/change-officers-page.less';
@import 'workitem/composed.less';
@import 'workitem/wait.less';
@import 'workitem/exercise-options.less';
@import 'workitem/hris.less';
@import 'workitem/monitor-approvals.less';
@import 'workitem/taxes.less';
@import 'accept-grant.less';
@import 'review-grant.less';
@import 'plugin-correction.less';
@import 'signature.less';
@import 'shoobx-rml.less';
@import 'deccorp-name-check.less';
@import 'finance.less';
@import 'employee-profile.less';
@import 'mobile.less';
@import 'tickets.less';
@import 'stakeholder-events.less';
@import 'components/column-sort.less';
@import 'reports.less';
@import 'orgchart.less';
@import 'billing.less';
@import 'portfolio.less';
@import 'shared-legend.less';
@import 'planner.less';
@import 'collect-representations.less';
@import 'constraints.less';
@import 'paylocity.less';
@import 'zendesk-chat-widget.less';
@import 'new_onboarding.less';
@import 'components/signature-capture.less';
@import 'onboarding.less';
@import 'partner-config/dla_public-rml.less';
@import 'jpm.less';
@import 'pie.less';
@import 'wi-templates.less';
@import 'message-templates.less';
@import 'exercise-purchase-details-page.less';
@import 'status-icons.less';

@media print {
  @import 'print.less';
}

.btn-group > sb-invite-button {
  float: left;
  position: relative;
  margin-right: -1px;

  .btn {
    border-radius: 0;
  }
}

ul.list-unstyled {
  &.dashboard-demo {
    display: flex;
    justify-content: space-between;
    > li {
      width: 32%;
      margin: 0 0 6px;
    }
  }
}

.d-flex {
  display: flex;
}
