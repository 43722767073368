.sign-documents-workitem {
  .btn-int-inverse {
    font-size: 12px;
    font-weight: normal;
    i {
      margin-right: 5px;
      padding: 0px;
    }
  }

  .document {
    border: 2px solid @sb-gray-lighter;
    border-left: none;
    border-right: none;
    padding-bottom: 15px;
    h4 {
      text-transform: none;
      font-weight: normal;
      margin-top: 0;
      margin-bottom: 0;
      .fa,
      .fas,
      .far,
      .fab {
        margin-right: 8px !important;
      }
    }
    .data {
      color: @fps-text-secondary;
    }
    .icons {
      color: @fps-text-secondary;
      line-height: 14px;
      font-size: 150%;
      position: relative;
      bottom: -6px;
      margin: 0 10px;
      i:not(:last-child) {
        margin-right: 4px;
      }
    }
    .date {
      font-weight: 400;
      margin-left: 10px;
    }
    small {
      font-weight: 400;
      font-size: 16px;
      &::before {
        content: 'Template: ';
        color: @fps-text-secondary;
        text-transform: uppercase;
        font-size: 12px;
        margin-left: 10px;
        border-left: 1px solid @sb-gray;
        padding-left: 10px;
        padding-right: 4px;
      }
    } // end small
  } // end .document
  .sig-preview {
    border: none;
    padding: 0;
    float: right;
    position: relative;
    top: -35px;
  }
  .sig-preview-investment {
    border: none;
    padding: 0;
    position: relative;
    display: inline;
  } // end .sig-preview
  .header {
    margin-top: 25px;
    margin-bottom: 10px;
    text-transform: uppercase;
    font-size: 16px;
    font-weight: bold;
    color: @fps-text-secondary;
    display: block;
  }
  .signature-preview-modal {
    display: inline;
    .signature-review {
      background: @fps-box-background;
      padding: 10px 30px;
    }
  }
  .sig-preview-body {
    .reveal-animation(0.3s);
    border: @sb-gray 1px solid;
    margin: 0px 0px 20px;
    button {
      margin: 10px 8% 0 0;
    }
    input,
    textarea {
      width: 90%;
    }
    .form-group:first-child {
      padding-top: 0;
      label {
        padding-top: 0;
      }
    }

    .signatureSaved {
      height: 25px;
      .summary {
        width: 60px;
      }
      .close {
        top: -13px;
        right: -225px;
      }
    }
  } // end .sig-preview-body

  .inline-pdf-preview {
    position: relative;
    margin-top: 20px;
  }

  .review-hidden {
    display: none;
  }
} // end .sign-documents-workitem
