/* -----[ Taxes WI Styling ]----------------- */
.taxes-receipt {
  border: 1px solid black;
  padding: 15px;
  margin: 0 0 22px;
}

.taxes-embeded {
  margin: 0 0 22px;

  .tax-event-header {
    display: flex;
    align-items: center;

    .tax-event-as-of {
      padding-left: 5px;
      font-size: 14px;

      .tax-event-attention {
        color: @fds-orange-700;
        .fa-flag {
          color: @fds-orange-700;
        }
      }
    }

    .tax-event-right-align {
      margin-left: auto;
    }
  }
}

.taxes-summary {
  border-top: 1px solid @sb-gray;
  margin-top: 15px;
  padding-top: 12px;
  display: flex;

  .taxes-summary-title {
    font-weight: bold;
    font-size: 16px;
    display: flex;
    flex-direction: column;
  }

  .taxes-summary-value {
    font-weight: bold;
    font-size: 24px;
    margin-left: auto;
    display: flex;
    flex-direction: column;
  }

  .taxes-summary-subtitle {
    font-weight: normal;
    font-size: 12px;
  }
}

.no-border {
  border-style: none;
}

.taxes-legal-disclaimer {
  color: @fds-neutral-500;
}
