@import (less) 'chosen.less';
@import (less) 'typeahead.less';

/**
 * Form Stylesheet Magic
 */

@form-ui-speed: 0.5s;

/*** General Form Styles ***/
.form-hr {
  border-top-width: 2px;
}
label {
  cursor: pointer;
}

label .sub {
  color: @fps-text-secondary;
}

.panel-disabled label,
.text-muted label,
.text-muted label h4,
label.checkradios-disabled,
label.disabled {
  color: @fps-text-secondary !important;
  cursor: auto;
}
.input-xs {
  height: 24px;
  padding: 1px 5px;
  font-size: 12px;
  line-height: 22px;
}
.form-actions {
  padding: 10px 0;
  margin-top: 0px;
  margin-bottom: 0px;
  border-top: 1px solid @sb-gray;
  *zoom: 1;
  background-color: #fff;
}
.form-horizontal .form-actions.row {
  text-align: center;
}
.form-padding {
  padding-left: 15px;
  padding-right: 15px;
  min-height: 1px;
  position: relative;
}
.option {
  cursor: pointer;
  > div {
    margin-left: 26px;
  }
}
div.required label {
  font-weight: 600;
}
.required-marker {
  color: @sb-blue;
  font-size: 7px;
  position: relative;
  top: -8px;
  ng-form &,
  sb-field-label & {
    font-family: 'Font Awesome 6 Free';
    font-weight: 900;
  }
}

@error-tooltip-col-width-in-widget: 25px + 2 * 12px;
.sb-widget,
.iter-widget-add-row {
  [sb-error-tooltip] {
    width: @error-tooltip-col-width-in-widget;
    margin-top: 8px;
    text-align: center;
  }
}

/*** Shoobx Widgets ***/
.sb-widget {
  > .widget-main {
    display: flex;
    align-items: flex-start;
    padding: 0 16px;

    .widget-main {
      padding-left: 0;
    }
  }

  .widget-prefix-field {
    margin-top: 1.5px;
  }

  // in case no error-tooltip, add margin to align columns
  sb-field-label + .input-body {
    margin-left: @error-tooltip-col-width-in-widget;
  }

  .input-group-addon {
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 35px;
    height: 35px;
    width: auto;
    border-radius: 0;
  }

  sb-field-label,
  .sb-field-label {
    display: block;
    width: 25%;

    label {
      margin: 0;
    }
  }

  .widget-pretext {
    font-style: italic;
    padding: 5px 15px 15px;
    h3 {
      font-style: normal;
    }
  }

  .input-body {
    display: flex;
    flex: 1;

    // sub-fields, e.g. address
    .form-group {
      margin: 0;
    }

    textarea {
      min-height: 150px;
    }

    .iterable-row {
      flex: 1;
      padding: 8px 15px;
      &:nth-child(2n) {
        background: @fps-list-odd;
      }
    }

    > sb-list,
    > sb-list-of-document,
    > sb-list-of-stakeholder,
    > sb-list-of-stock-ticker,
    > sb-record-table {
      flex: 1;
    }
  }
} // end .sb-widget

sb-field-configuration-listing {
  .form-horizontal {
    .widget-main {
      flex-wrap: wrap;
      > sb-field-label {
        width: 90%;
      }
      > .fa-exclamation-triangle {
        width: 10%;
      }
      > .fa-exclamation-triangle,
      > sb-field-label {
        margin: 0 0 10px;
      }
      > .input-body {
        width: 100%;
      }
    }
  }
}

.formly-field:not(:first-child) {
  > .sb-widget.form-group {
    border-top: 2px solid @sb-gray-lighter;
  }
  .no-field-separators & > .sb-widget.form-group {
    border-top: 0;
  }
}
.no-labels-full-width .col-md-8 {
  padding: 0 !important;
  width: 100%;
}

sb-same-affiliate-warning,
sb-stakeholder-form {
  display: block;
}

sb-same-affiliate-warning .alert {
  margin: 15px 0 5px;
}

[sb-error-tooltip] {
  @widget-tooltip-arrow-width: 10px;
  @right-movement: 28px;
  .generic-animation(@form-ui-speed);
  color: @sb-red;
  font-size: 20px;
  opacity: 0;
  visibility: hidden;
  & + .tooltip .tooltip-inner {
    background: @sb-red-type;
    font-size: 14px;
    min-width: 200px;
    padding: 8px;
    font-size: 16px;
    ul {
      margin: 0;
      padding: 0 0 0 20px;
      text-align: left;
    }
  }
  & + .tooltip.top {
    padding-bottom: @widget-tooltip-arrow-width;
  }
  & + .tooltip.top .tooltip-arrow {
    border-top-color: @sb-red-type;
    border-width: @widget-tooltip-arrow-width @widget-tooltip-arrow-width 0;
    margin-left: -@widget-tooltip-arrow-width;
  }
}

.sb-widget-error,
.ng-submitted:not(.process-form) .ng-invalid[data-sb-forms-list] .iter-widget-add-row {
  [sb-error-tooltip] {
    opacity: 1;
    visibility: visible;
  }
}

.sb-widget-error {
  input[type='number'],
  input[type='password'],
  input[type='email'],
  input[type='text'] {
    .generic-animation(@form-ui-speed);
    &:not(.chosen-drop input):focus {
      box-shadow:
        inset 0 1px 1px rgba(0, 0, 0, 0.075),
        0 0 6px @sb-red-shadow;
    }
  }
  .form-dropdown,
  textarea,
  input[type='number'],
  input[type='email'],
  input[type='password'],
  input[type='text'] {
    border-color: @sb-red-type;
  }
  .form-dropdown.focus {
    box-shadow:
      inset 0 1px 1px rgba(0, 0, 0, 0.075),
      0 0 6px @sb-red-shadow;
  }
}
.forms-subfield-arrow(@i, @subfield-arrow-indent, @subfield-arrow-width) {
  position: relative;
  &::before {
    font-family: 'Shoobx';
    color: @fps-text-secondary;
    content: '\003E';
    position: absolute;
    top: 3px;
    left: @subfield-arrow-indent + (@i - 1) * @subfield-arrow-width;
    font-weight: bold;
  }
}
.forms-generate-subfields(@n, @i: 1) when (@i =< @n) {
  @subfield-arrow-indent: 20px;
  @subfield-arrow-width: 20px;
  .sb-widget-subfield-@{i} sb-field-label label {
    .forms-subfield-arrow(@i, @subfield-arrow-indent, @subfield-arrow-width);
    padding-left: @subfield-arrow-indent + @i * @subfield-arrow-width;
  }
  .forms-generate-subfields(@n, @i + 1);
}
@forms-subfield-depth: 4;
.forms-generate-subfields(@forms-subfield-depth);

.forms-generate-right-subfields(@n, @i: 1) when (@i =< @n) {
  @subfield-arrow-indent: 30px;
  @subfield-arrow-width: 0px;
  .sb-widget-right-subfield-@{i} {
    padding-left: @subfield-arrow-indent * @i + @subfield-arrow-width * (@i);
  }
  .forms-generate-right-subfields(@n, @i + 1);
}
@forms-subfield-depth: 4;
.forms-generate-right-subfields(@forms-subfield-depth);

/*** Widgets ***/
//== Checkradios Widget
.checkradios-radio,
.checkradios-checkbox {
  margin: 3px 3px 0 0;
  width: 17px;
  height: 17px;
  padding: 2px;
  top: -1px;
  background: @sb-white;
  border: 1px solid @sb-gray;
  cursor: pointer;
  overflow: visible;
  font-family: 'Font Awesome 6 Free';
  font-weight: 900;
  font-style: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
  position: relative;
  display: inline-block;
  vertical-align: bottom;
  text-align: center;
}
.checkradios-read-only {
  border: none;
}
.checkradios-innerwrap input {
  .hidden-off-screen();
}
td .checkradios-radio,
td .checkradios-checkbox {
  margin: 0 7px 0 0;
  top: -9px;
}
.select-shareholders td .checkradios-radio,
.select-shareholders td .checkradios-checkbox {
  top: -3px;
}
.checkradios-radio {
  border-radius: 50%;
}

.checkradios-disabled + span,
.checkradios-disabled div,
.checkradios-disabled {
  border-color: @sb-gray-light;
  color: @fps-text-secondary;
  cursor: auto;
}

.checkradios-implied + span,
.checkradios-implied div,
.checkradios-implied {
  border-color: @sb-gray-light;
  color: @fps-text-secondary;
}

.checkradios-checkbox.small {
  height: 12px;
  width: 12px;
}
.checkradios-radio.small {
  height: 13px;
  width: 13px;
}
.checkradios-check,
.checkradios-circle {
  display: none;
  position: absolute;
  color: @fps-text;
}
.checkradios-check {
  font-size: 21px;
  top: -4px;
  left: -4px;

  &.fa-minus {
    color: @sb-green;
    transform: scale(0.7, 1);
    margin-left: -1.5px;
  }
}
.checkradios-circle {
  background-color: @fps-text;
  font-size: 12px;
  top: 2px;
  left: 2px;
  height: 11px;
  width: 11px;
  border-radius: 50%;
}
.checkradios-checkbox.checkradios-selected .checkradios-check,
.checkradios-radio.checkradios-selected .checkradios-circle {
  display: block;
}
.checkradios-checkbox.checkradios-indeterminate .checkradios-check,
.checkradios-radio.checkradios-indeterminate .checkradios-circle {
  display: block;
}
.panel-disabled .checkradios-circle,
.checkradios-disabled .checkradios-circle {
  background-color: @sb-gray-light;
}
.panel-disabled .checkradios-check,
.checkradios-disabled .checkradios-check {
  color: @fps-text-secondary;
}
.checkradios-checkbox.small .checkradios-check {
  font-size: 13px;
  top: -2px;
  left: -2px;
}
.checkradios-radio.small .checkradios-circle {
  height: 7px;
  width: 7px;
  top: 2px;
  left: 2px;
}
.checkradios-focus {
  border-color: @sb-green;
  -webkit-box-shadow:
    inset 0 1px 1px rgba(0, 0, 0, 0.075),
    0 0 5px rgba(141, 198, 63, 0.4);
  box-shadow:
    inset 0 1px 1px rgba(0, 0, 0, 0.075),
    0 0 5px rgba(141, 198, 63, 0.4);
}
sb-checkbox {
  display: inline-block;
  sbx-checkbox {
    display: inline-block;
  }
}
.option {
  .checkradios-checkbox,
  .checkradios-radio {
    float: left;
    margin: 5px 0 0 !important;
  }
}
//== Document Selector Tree Widget
sb-tree {
  .toggle-branch {
    cursor: pointer;
  }
  sb-single-select-tree-listing {
    span:before {
      padding-right: 5px;
      color: @sb-green-type;
    }
  }
}

.tree-node {
  .small.checkradios-radio,
  .small.checkradios-checkbox {
    margin: 0 3px 2px 0;
  }
  li {
    list-style-type: none;
  }
  label {
    padding: 0;
    margin: 0;
    margin-left: 20px;
  }
  .documents {
    padding: 0;
    margin: 0;
    margin-left: 14px;
  }
}

/*** Richtext ***/
@sb-form-richtext-height: 290px;
@sb-form-richtext-toolbar-height: 40px;
sbx-editor {
  height: @sb-form-richtext-height !important;
  padding: 0;
  display: block;
  &.rml-content {
    margin: 0;
  }
  &:not(.rml-content) .editor {
    font-family: 'Source Sans Pro', Helvetica, Arial, sans-serif !important;
    font-size: 16px !important;
  }
  .ck.ck-editor {
    position: relative;
  }
  .ck.ck-content {
    height: @sb-form-richtext-height - @sb-form-richtext-toolbar-height;
    color: @fps-text;
  }
  .ck.ck-editor__main {
    height: 100%;
    cursor: text;
    outline: none;
    overflow-y: auto;
    white-space: pre-wrap;
    word-wrap: break-word;
  }
  &.document-richtext-widget {
    .ck.ck-editor {
      font-family: 'Garamond' !important;
      font-size: 17px !important;
    }
  }
}

/*** Iterable Widgets ***/
.iter-widget-row {
  display: block;
  padding-top: 10px;
  &:first-child:not(.iter-widget-add-row) {
    padding-top: 0;
  }
  label {
    cursor: auto;
  }
}

.iter-widget-row[ng-repeat] {
  .reveal-animation(0.2s);
}

.iter-widget-remove {
  padding-top: 5px;
  padding-left: 0;
  .btn {
    font-size: 18px;
  }
}

[data-sb-check-list],
[sb-check-list] {
  .select-all {
    margin-left: -15px;
  }
  padding-top: 10px;
  sb-switch {
    margin-left: 8px;
  }
}
.read-only-value {
  padding: 7px 15px 0;
  a {
    margin-left: 5px;
  }
}

/*** Date Time ***/
.date input,
.date-widget {
  width: 220px !important;
  display: flex;
  align-items: center;

  .input-group-addon {
    // It's inside a span so we use line-height to center the element inside it.
    // 23px = 35px (input height) - 2*5px (padding) - 2px (border)
    line-height: 23px;
    width: 100%;
    border-radius: 0;
  }
}

.uib-timepicker {
  .uib-separator {
    padding: 0 5px;
  }
  input {
    width: 60px !important;
  }
  .am-pm .btn {
    margin-left: 5px;
    width: 60px !important;
    height: 35px;
    background: @sb-gray-light;
  }
  tr.text-center {
    display: none;
  }
}
.uib-datepicker-popup {
  width: 263px;
  color: @fps-text;
  padding-left: 15px;
  padding-right: 15px;
  &,
  table,
  .btn,
  .btn:focus {
    outline: 0;
  }
  table {
    margin: 0 auto;
  }
  thead th,
  tbody td {
    padding: 0;
    .btn {
      outline: 0;
      padding: 7px;
      border: none;
      background: none;
      text-transform: none;
    }
    .text-info {
      color: @fps-text;
      font-weight: bold;
    }
    .btn.active {
      color: #fff;
      background: @sb-green;
      box-shadow: none;
      .text-info {
        color: #fff;
      }
    }
  } // end td, th
} // end uib-datepicker-popup

/*** Checkbox grid ***/
.sb-checkbox-grid {
  th {
    text-align: center;
  }
  .col-separator {
    border-right: 2px solid @sb-gray;
  }
  tbody {
    border: 6px solid @fps-text;
    button {
      padding-top: 1px;
      padding-left: 2px;
      padding-right: 2px;
      min-width: 28px;
      background: @sb-gray-light;
      .fa:before,
      .far:before,
      .fas:before,
      .fab:before {
        content: '\f0d7'; // fa-caret-right
      }
    }
    &.closed button {
      .fa:before,
      .far:before,
      .fas:before,
      .fab:before {
        content: '\f0da'; // fa-caret-down
      }
    }
    tr:first-child {
      background: @sb-gray-light;
      td {
        background: transparent;
      }
    }
    tr:not(:first-child) {
      > td,
      > td > div {
        transition: 0.2s ease all;
        overflow: hidden;
        max-height: 150px;
      }
    }
    &.closed tr:not(:first-child) {
      > td,
      > td > div {
        padding-top: 0;
        padding-bottom: 0;
        max-height: 0px;
      }
    }
    td .checkradios-checkbox {
      margin-top: 13px;
    }
    td:first-child {
      width: 300px;
    }
    td:not(:first-child) {
      text-align: center;
    }
    td .option .checkradios-radio {
      float: none;
    }
  } // end tbody
  .fa-question-circle {
    color: @fps-text-secondary;
    padding-left: 4px;
  }
  .info-text {
    color: @fps-text-secondary;
    font-size: 14px;
    text-align: center;
    vertical-align: middle;
  }
} // end sb-checkbox-grid

/*** sb-exp-date ***/
sb-exp-date {
  & + .error-tooltip-wrap {
    top: -32px;
    right: -2px;
  }
}

/*** Field Help ***/
.field-help {
  cursor: default;
  color: @fps-text-secondary;
  ng-form &,
  sb-field-label & {
    font: normal normal 900 16px/1 'Font Awesome 6 Free';
  }
}

.field-help-always-visible {
  display: block;
  font-size: 14px;
  font-weight: normal;
  padding-top: 10px;
}
.field-help + input,
div.popover + input,
.field-help + select,
div.popover + select {
  width: 95%;
}
div.popover {
  /* Otherwise popovers hide under the top navbar */
  z-index: 1300;
}

sb-switch {
  @switch-width: 40px;
  display: inline-flex;
  align-items: center;
  text-transform: uppercase;
  font-size: 15px;
  width: @switch-width + 33px;
  .formly-field & {
    margin-top: 11px;
    float: right;
  }
  .switch-container {
    @switch-height: 15px;
    @button-padding: 3px;
    @button-size: (@switch-height - @button-padding * 2);
    @animation-time: 0.15s;
    position: relative;
    height: @switch-height;
    cursor: pointer;
    width: @switch-width;
    background-color: @sb-gray;
    border-radius: (@switch-height / 2);
    display: inline-block;
    margin-right: @button-padding;
    transition: @animation-time ease background;
    &::before {
      content: '';
      width: @button-size;
      height: @button-size;
      border-radius: (@button-size / 2);
      top: @button-padding;
      left: @button-padding;
      position: absolute;
      background-color: @fps-text;
      transition: @animation-time ease left;
    }
    &.switch-on {
      &::before {
        left: (@switch-width - @button-size - @button-padding);
      }
      background-color: @sb-green;
    }
  }
}

sb-department-chooser {
  li {
    padding: 10px;
  }
  li:hover:not(.selected) {
    background: @sb-gray-light;
  }
}

sb-stock-ticker-chooser {
  display: inline-block;
  width: 90%;

  li {
    padding: 10px;
  }
  li:hover:not(.selected) {
    background: @sb-gray-light;
  }
}

sb-pdf-upload {
  width: 100%;
  display: inline-block;
}
.form-modal sb-compiled-content.form-horizontal .form-group {
  margin-left: 0;
  margin-right: 0;
}

sb-obscured-input {
  display: flex;
  > input {
    flex: 1;
  }
  > button {
    margin-top: 6px;
    margin-left: 10px;
  }
}

sb-document-reference {
  .input-group & sb-collapsed-actions {
    display: block;
    margin-top: 5px;
  }
  .input-body & sb-collapsed-actions {
    display: block;
    margin-top: 3px;
  }

  .dropdown-menu {
    min-width: 270px !important;
  }
  .filled-out-document {
    background: @fps-list-label;
    padding: 5px;
    border: 1px solid @sb-gray-light;
    display: inline-block;
    i.fa,
    i.fas,
    i.far,
    i.fab {
      color: @sb-green-type;
      margin-right: 5px;
    }
    strong:last-child {
      margin-left: 10px;
      cursor: pointer;
    }
  }

  .uploaded-document-review-button {
    cursor: pointer;
    margin: 0 10px;
    i.fas {
      color: @sb-green-type;
    }
  }
}

sb-radio-form {
  display: block;
  > [sb-radio] > label.radio {
    padding: 10px 0 0 10px;
    min-height: 20px;
  }
  [ng-transclude] {
    margin-left: 30px;
    > *:not(sb-radio-form) {
      display: block;
      margin-bottom: -10px;
    }
  }
}

.formly-field-enum-radios,
.formly-field-reference-radios {
  .input-body {
    flex-direction: column;
    align-items: flex-start;
  }
  .input-body-row {
    flex-direction: row;
    align-items: flex-start;
    .row {
      margin: 0 0 0 0;
    }
  }
}

.hidden-form-control {
  display: none !important;
}

sb-dictionary {
  @value-margin-left: 10px;
  @colon-margin: 20px + @value-margin-left;
  display: flex;
  flex-direction: column;

  .input-body > & {
    flex: 1;
  }

  > .label-row {
    display: flex;
    label {
      flex: 1;
    }
  }

  .dict-key,
  .dict-value {
    flex: 1;
    max-width: 45%; // quick fix for chosen elements
  }

  .dict-key {
    padding-right: @colon-margin;
  }

  .iter-widget-colon {
    display: flex;
    justify-content: center;
    color: @fps-text-secondary;
    font-size: 30px;
  }

  > .iterable-row > formly-field > .form-group > .widget-main {
    > .fa-exclamation-triangle {
      width: auto;
    }
  }

  .dict-value > .form-group > .widget-main {
    > .input-body {
      margin-left: @value-margin-left;
    }
  }
}

.pick-from-dataroom-modal {
  .modal-dialog {
    height: calc(100% - 60px);

    .modal-content {
      display: flex;
      flex-direction: column;
      max-height: 100%;
    }

    sbx-modal-wrapper,
    .modal-body {
      display: flex;
      flex-direction: column;
      overflow: hidden;
    }

    .modal-body-scroll {
      overflow-y: auto;
    }

    .modal-header,
    .modal-footer {
      flex-shrink: 0;
    }
  }

  .modal-body {
    .modal-body-scroll > header {
      display: flex;
      > .crumbs {
        width: 60%;
        padding: 6px 12px 0;
      }
      > .search-field {
        position: relative;
        width: 40%;
        input {
          width: 100%;
          padding-right: 30px;
        }
        input.ng-empty + button {
          pointer-events: none;
        }
        button {
          outline: none;
          position: absolute;
          top: 9px;
          right: 9px;
        }
      }
    }
  }

  .content-wrapper {
    padding: 20px;
  }
  sb-dataroom-listing {
    margin-top: 10px;
  }
}

.record-body {
  flex-direction: column;
  flex: 1;
  .widget-main .widget-main {
    padding: 0;
  }
}

.record-header-label {
  display: block;
}
sb-record {
  formly-field > .form-group {
    padding-right: 0;
    padding-left: 20px;
  }
}

sb-show-page,
sb-metadata-endpoint-form {
  .widget-pretext {
    padding-bottom: 10px;
  }
}

sb-address-form {
  display: block;
  width: 100%;
}

sb-dictionary,
sb-list {
  formly-field {
    flex: 1;
  }

  .iter-widget-remove,
  sb-list-remove {
    margin-left: 20px;
  }

  .iterable-row,
  sb-list-remove {
    display: flex;
    align-items: center;
  }

  .sb-widget > .widget-main {
    padding: 0;
  }
}

sb-list-of-document,
sb-list-of-stock-ticker,
sb-list-of-stakeholder {
  .iterable-row {
    display: flex;

    &:first-child:last-child {
      padding: 0;
    }

    &:last-child {
      background: none !important;
    }
  }
  [data-sb-error-tooltip] {
    line-height: 1.85;
  }
}

sb-list-of-document {
  margin-top: 3px;
  .iterable-row {
    &:not(:last-child) {
      padding-bottom: 8px;
    }
    &:last-child:not(:first-child) {
      padding-top: 5px;
    }
  }
  sb-document-reference {
    flex: 1;

    .fa-file-alt {
      font-size: 13px;
      margin-left: 5px;
    }

    .filled-out-document {
      display: flex;
      align-items: center;

      strong.fa-times:last-child {
        margin-left: auto;
      }
    }
  }
}

sb-list-of-stakeholder {
  sb-stakeholder-chooser {
    flex: 1;
  }

  &:last-child {
    background: none;
  }
}

sb-list-of-stock-ticker {
  margin-top: 3px;
  .iterable-row {
    &:not(:last-child) {
      padding-bottom: 8px;
    }
    &:last-child:not(:first-child) {
      padding-top: 5px;
    }
  }

  sb-stock-ticker-chooser {
    flex: 1;
  }

  &:last-child {
    background: none;
  }
}

.modal-body {
  // Smaller margins between alerts when they're next to each other
  .alert-banner-container {
    sb-alert-banner:not(:last-child) {
      .alert {
        margin-bottom: 5px;
      }
    }
  }
}

sb-form {
  .fa-exclamation-triangle {
    color: @sb-red;
  }
}
